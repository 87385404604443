import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PokemonCard from './pokemon-card-detail.tsx';
import SetPage from './pokemon-set-detail.tsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SetsOverviewPage from './pokemon-sets-overview.tsx';
import AllTCGsPage from './all-tcgs.tsx';
import { LoadingProvider } from './loadingContext.js';
import LoadingScreen from './loadingScreen.tsx';
import ImprintPage from './imprint.tsx';
import TermsOfServicePage from './tos.tsx';
import PrivacyPolicyPage from './privacyPolicy.tsx';
import AboutPage from './about.tsx';
import { ThemeProvider } from './themeContext';
import LoginPage from './login.tsx';
import SignUpPage from './signup.tsx'
import PokemonTCGSearch from './pokemon-card-search.tsx';
import DeckBuilder from './pokemon-deck-builder.tsx';
import SupportPage from './supportPage.tsx';
import BannedCardsPage from './banned.tsx';
import ArtistsPage from './artists.tsx';
import SingleArtistPage from './singleArtist.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <LoadingProvider>
        <Router>
          <LoadingScreen />
          <Routes>
            <Route path="/" element={<AllTCGsPage />} />
            <Route path="/pokemon-sets/:setId/:cardId" element={<PokemonCard />} />
            <Route path="/pokemon-sets/:setId" element={<SetPage />} /> 
            <Route path="/pokemon-sets" element={<SetsOverviewPage />} />
            <Route path="/imprint" element={<ImprintPage />} />
            <Route path="/tos" element={<TermsOfServicePage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/pokemon-search" element={<PokemonTCGSearch />} />
            <Route path="/pokemon-deck-builder" element={<DeckBuilder />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/banned-cards" element={<BannedCardsPage />} />
            <Route path="/pokemon-artists" element={<ArtistsPage />} />
            <Route path="/pokemon-artists/:artistName" element={<SingleArtistPage />} />
          </Routes>
        </Router>
      </LoadingProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
