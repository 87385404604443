import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import SetDetails from './components/set/setDetails.tsx';
import CardList from './components/set/cardList.tsx';
import SetStatistics from './components/set/setStats.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import { ThemeContext } from './themeContext';

const SetPage = () => {
  const { setId } = useParams();
  const [setData, setSetData] = useState(null);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const fetchSetData = async () => {
      try {
        const response = await axios.get(`/api/sets/${setId}`);
        const data = response.data;

        console.log('Received set data:', data);
        console.log('Set name:', data.name);
        console.log('Set series:', data.series);
        console.log('First card:', data.Cards[0]);
        console.log('First card cardmarket data:', data.Cards[0].cardmarket);

        setSetData(data);
        setCards(data.Cards);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching set data:', err);
        setError('Failed to fetch set data');
        setLoading(false);
      }
    };

    fetchSetData();
  }, [setId]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  if (loading) return <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
  </div>;
  if (error) return <div className="text-center text-red-500 text-xl mt-8">Error: {error}</div>;

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <Breadcrumbs setData={setData} />
          <div className="container mx-auto px-4 py-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <SetDetails set={setData} cards={cards} />
            <SetStatistics set={setData} cards={cards} />
            <CardList cards={cards} set={setData} />
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default SetPage;