import React, { useState, useMemo, useContext } from 'react';
import { ThemeContext } from '../../themeContext.js'; // Adjust the import path as needed

const MarketPrices = ({ tcgplayer, cardmarket }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const variants = useMemo(() => 
    tcgplayer?.prices ? Object.keys(tcgplayer.prices).map(v => v.charAt(0).toUpperCase() + v.slice(1)) : [], 
    [tcgplayer]
  );
  const [selectedVariant, setSelectedVariant] = useState(variants[0] || null);

  const isTrendHigher = (cardmarket?.prices?.trendPrice ?? 0) > (cardmarket?.prices?.avg1 ?? 0);

  const formatPrice = (value, currency = '$') => {
    return value != null ? `${currency}${value.toFixed(2)}` : 'N/A';
  };

  const labelClass = isDarkMode ? 'text-gray-400' : 'text-gray-600';
  const valueClass = isDarkMode ? 'text-white' : 'text-gray-800';
  const borderClass = isDarkMode ? 'border-gray-700' : 'border-gray-200';

  const renderPriceTable = (prices, currency = '$') => (
    <table className="w-full">
      <tbody>
        {Object.entries(prices).map(([label, value]) => (
          label !== 'directLow' && (
            <tr key={label} className={`border-b last:border-b-0 ${borderClass}`}>
              <td className={`py-3 ${labelClass} capitalize`}>{label}:</td>
              <td className={`py-3 font-bold text-right ${valueClass}`}>{formatPrice(value, currency)}</td>
            </tr>
          )
        ))}
      </tbody>
    </table>
  );

  const renderTCGPlayerPrices = () => {
    if (!tcgplayer?.prices || !selectedVariant) return <p className={valueClass}>No TCGPlayer pricing available</p>;
    const prices = {...tcgplayer.prices[selectedVariant.toLowerCase()]};
    delete prices.directLow;
    return renderPriceTable(prices);
  };

  const renderCardmarketPrices = () => {
    if (!cardmarket?.prices) return <p className={valueClass}>No Cardmarket pricing available</p>;
    const prices = {
      "Trend": cardmarket.prices.trendPrice,
      "30-day Avg": cardmarket.prices.avg30,
      "7-day Avg": cardmarket.prices.avg7,
      "1-day Avg": cardmarket.prices.avg1
    };
    return (
      <table className="w-full">
        <tbody>
          {Object.entries(prices).map(([label, value]) => (
            <tr key={label} className={`border-b last:border-b-0 ${borderClass}`}>
              <td className={`py-3 ${labelClass}`}>{label}:</td>
              <td className={`py-3 font-bold text-right ${
                label === "Trend" 
                  ? (isTrendHigher ? 'text-green-500' : 'text-red-500') 
                  : valueClass
              }`}>
                €{value.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className={`bg-white dark:bg-gray-800 border ${borderClass} rounded-xl overflow-hidden`}>
        <div className={`p-4 border-b ${borderClass} flex items-center justify-between`}>
          <img src="/img/tcgplayer_logo.png" alt="TCGplayer" className={`h-8 object-contain ${variants.length <= 1 ? 'mx-auto' : ''}`} />
          {variants.length > 1 && (
            <select
              value={selectedVariant}
              onChange={(e) => setSelectedVariant(e.target.value)}
              className={`ml-4 pl-3 pr-10 py-2 text-sm ${borderClass} focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'}`}
            >
              {variants.map((variant) => (
                <option key={variant} value={variant}>
                  {variant}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="p-6">
          {renderTCGPlayerPrices()}
          {tcgplayer?.url && (
            <a 
              href={tcgplayer.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block w-full text-center bg-blue-500 text-white py-3 rounded font-semibold hover:bg-blue-600 transition-colors duration-200 mt-4"
            >
              Buy on TCGplayer
            </a>
          )}
        </div>
      </div>
      
      <div className={`bg-white dark:bg-gray-800 border ${borderClass} rounded-xl overflow-hidden`}>
        <div className={`p-4 border-b ${borderClass} flex items-center justify-center`}>
          <img src="/img/cardmarket_logo.png" alt="Cardmarket" className="h-8 object-contain" />
        </div>
        <div className="p-6">
          {renderCardmarketPrices()}
          {cardmarket?.url && (
            <a 
              href={cardmarket.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block w-full text-center bg-green-500 text-white py-3 rounded font-semibold hover:bg-green-600 transition-colors duration-200 mt-4"
            >
              Buy on Cardmarket
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketPrices;