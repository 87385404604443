import React, { useContext } from 'react';
import { ThemeContext } from '../../themeContext.js';

const LanguageSwitch = ({ onLanguageChange, selectedLanguage }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const languages = [
    { code: 'EN', name: 'English' },
    { code: 'DE', name: 'Deutsch' },
    { code: 'FR', name: 'Français' },
    { code: 'IT', name: 'Italiano' },
    { code: 'ES', name: 'Español' },
  ];

  return (
    <div className="mb-4">
      <div className="flex flex-wrap gap-2">
        {languages.map((lang) => (
          <button
          key={lang.code}
          onClick={() => onLanguageChange(lang.code)}
          className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
            selectedLanguage === lang.code
              ? 'bg-blue-500 text-white'
              : isDarkMode
                ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
          title={lang.name}
        >
            {lang.code}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitch;