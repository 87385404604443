import React from 'react';

const typeIconSuffixes: { [key: string]: string } = {
  Colorless: 'wzj',
  Darkness: 'x06',
  Dragon: 'x0m',
  Lightning: 'x16',
  Fairy: 'x1n',
  Fighting: 'x1z',
  Fire: 'x2m',
  Grass: 'x3b',
  Psychic: 'x4c',
  Metal: 'x4z',
  Water: 'x5f'
};

interface TypeIconProps {
  type: string;
}

const TypeIcon: React.FC<TypeIconProps> = ({ type }) => {
  const suffix = typeIconSuffixes[type] || 'wzj'; // Default to Colorless if type not found
  const iconSrc = `/img/pkm/type/${type.toLowerCase()}_energy_card_vector_symbol_by_biochao_dezr${suffix}-pre.png`;

  console.log('Type:', type);
  console.log('Constructed URL:', iconSrc);

  return <img src={iconSrc} alt={`${type} type`} className="w-6 h-6 inline-block mr-1" />;
};

export default TypeIcon;