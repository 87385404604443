import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import { ThemeContext } from './themeContext';

const TCGCard = ({ name, logo, info, comingSoon = true }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const cardBgClass = isDarkMode ? 'bg-gray-800' : 'bg-white';
  const textClass = isDarkMode ? 'text-white' : 'text-gray-800';
  const subTextClass = isDarkMode ? 'text-gray-300' : 'text-gray-600';
  const gradientClass = isDarkMode 
    ? 'bg-gradient-to-br from-gray-700 to-gray-600' 
    : 'bg-gradient-to-br from-gray-100 to-gray-200';

  return (
    <div className={`${cardBgClass} rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 relative`}>
      <Link to={comingSoon ? '#' : '/pokemon-sets'} className="block">
        <div className={`h-48 ${gradientClass} flex items-center justify-center p-4`}>
          <img src={`${process.env.PUBLIC_URL}${logo}`} alt={`${name} logo`} className="max-h-full max-w-full object-contain" />
        </div>
        <div className="p-6">
          <h3 className={`text-xl font-bold ${textClass} mb-2`}>{name}</h3>
          <p className={`text-sm ${subTextClass}`}>{info}</p>
        </div>
        {comingSoon && (
          <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
            <span className="text-white text-2xl font-bold">Coming Soon</span>
          </div>
        )}
      </Link>
    </div>
  );
};

const AllTCGsPage = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const tcgs = [
    {
      name: "Pokémon",
      logo: "/img/pkm/pokemon_card_game.png",
      info: "Catch 'em all in this strategic card game featuring your favorite Pokémon.",
      comingSoon: false
    },
    {
      name: "Yu-Gi-Oh!",
      logo: "/img/ygo/one_piece_card_game.webp", // Note: This is actually the Yu-Gi-Oh! logo
      info: "Duel with powerful monsters, spells, and traps in this classic trading card game."
    },
    {
      name: "Digimon",
      logo: "/img/digi/digimon_card_game.webp",
      info: "Raise and battle digital monsters in this exciting card game adventure."
    },
    {
      name: "Magic: The Gathering",
      logo: "/img/magic/magic_card_game.png",
      info: "Cast spells and summon creatures in the world's first trading card game."
    },
    {
      name: "One Piece",
      logo: "/img/op/one_piece_card_game.webp",
      info: "Set sail for adventure with Luffy and crew in this pirate-themed card game."
    }
  ];

  const bgClass = isDarkMode ? 'bg-gray-900' : 'bg-gray-100';

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />

        <main className={`flex-1 overflow-x-hidden overflow-y-auto ${bgClass}`}>
          <Breadcrumbs cardData={null} />
          <div className="container mx-auto px-8 py-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
              {tcgs.map((tcg, index) => (
                <TCGCard key={index} {...tcg} />
              ))}
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default AllTCGsPage;