import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../themeContext.js'; // Adjust the import path as needed

const SetDetails = ({ set, cards }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const actualTotalCards = cards ? cards.length : set.printedTotal;
  const specialCards = actualTotalCards - set.printedTotal;

  const getLocalizedText = (text) => {
    if (typeof text === 'object' && text.en) {
      return text.en;
    }
    return text;
  };

  const seriesAnchor = getLocalizedText(set.series).replace(/\s+/g, '-').toLowerCase();
  const seriesUrl = `/pokemon-cards#${seriesAnchor}`;

  const bgClass = isDarkMode ? 'bg-gray-800' : 'bg-white';
  const textClass = isDarkMode ? 'text-white' : 'text-gray-800';
  const subTextClass = isDarkMode ? 'text-gray-300' : 'text-gray-600';

  return (
    <div className={`${bgClass} rounded-2xl p-6`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <img src={`/img/pkm/pokemon_sets/icons/${set.id}_symbol.png`} alt={`${getLocalizedText(set.name)} symbol`} className="w-16 h-16 mr-4" />
          <h1 className={`text-4xl md:text-5xl font-extrabold ${textClass}`}>{getLocalizedText(set.name)}</h1>
        </div>
        <img src={`/img/pkm/pokemon_sets/images/${set.id}_logo.png`} alt={`${getLocalizedText(set.name)} logo`} className="w-auto h-20" />
      </div>
      <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${subTextClass}`}>
        <div>
          <p className="mb-2">
            <span className={`font-semibold ${textClass}`}>Series:</span>{' '}
            <Link to={seriesUrl} className="text-blue-500 hover:text-blue-400 transition-colors duration-200">
              {getLocalizedText(set.series)}
            </Link>
          </p>
          <p className="mb-2">
            <span className={`font-semibold ${textClass}`}>Total Cards:</span> {actualTotalCards} / {set.printedTotal}
            <span className="text-sm ml-2">({specialCards} special)</span>
          </p>
          <p className="mb-2"><span className={`font-semibold ${textClass}`}>Release Date:</span> {set.releaseDate}</p>
        </div>
        <div>
          <p className="mb-2"><span className={`font-semibold ${textClass}`}>Legality:</span> {Object.entries(set.legalities).map(([format, legality]) => `${format}: ${legality}`).join(', ')}</p>
          <p className="mb-2"><span className={`font-semibold ${textClass}`}>PTCGO Code:</span> {set.ptcgoCode}</p>
        </div>
      </div>
    </div>
  );
};

export default SetDetails;