import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import RarityLegend from './rarityLegend.tsx';
import { ThemeContext } from '../../themeContext.js';

const DuplicatesTable = ({ cardData }) => {
  const [duplicates, setDuplicates] = useState([]);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const fetchDuplicates = async () => {
      try {
        const response = await fetch(`/api/cards/set/${cardData.setId}/cards`);
        if (!response.ok) {
          throw new Error('Failed to fetch set cards');
        }
        const allCards = await response.json();

        const duplicateCards = allCards.filter(card => 
          card.name.en === cardData.name.en && 
          card.id !== cardData.id
        );

        const formattedDuplicates = [cardData, ...duplicateCards].map(card => ({
          ...card,
          images: {
            small: `/img/pokemon_cards_en/images/${card.id}.webp`,
            large: `/img/pokemon_cards_en/images/${card.id}.webp`
          }
        }));

        setDuplicates(formattedDuplicates.sort((a, b) => 
          a.number.localeCompare(b.number, undefined, { numeric: true })
        ));
      } catch (error) {
        console.error('Error fetching duplicate cards:', error);
      }
    };

    fetchDuplicates();
  }, [cardData]);

  if (duplicates.length <= 1) {
    return null;
  }

  return (
    <div className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border rounded-xl overflow-hidden mt-8`}>
      <div className="p-6">
        <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Duplicate Cards</h2>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className={`${isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-50 text-gray-600'} text-sm leading-normal`}>
                <th className="py-3 px-6 text-left">Image</th>
                <th className="py-3 px-6 text-left">Card ID</th>
                <th className="py-3 px-6 text-left">Set</th>
                <th className="py-3 px-6 text-left">Rarity</th>
                <th className="py-3 px-6 text-right">Current Price</th>
                <th className="py-3 px-6 text-left">Release Date</th>
              </tr>
            </thead>
            <tbody className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} text-sm`}>
              {duplicates.map((card) => (
                <tr key={card.id} className={`border-b ${isDarkMode ? 'border-gray-700 hover:bg-gray-700' : 'border-gray-200 hover:bg-gray-50'} transition-colors duration-150`}>
                  <td className="py-4 px-6">
                    <Link to={`/pokemon-sets/${card.setId}/${card.id}`}>
                      <img src={card.images.small} alt={card.name.en} className="w-16 h-22 object-contain rounded-lg shadow-sm" />
                    </Link>
                  </td>
                  <td className="py-4 px-6 font-medium">
                    <Link to={`/pokemon-sets/${card.setId}/${card.id}`} className={`hover:text-blue-600 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
                      {card.id.toUpperCase()}
                    </Link>
                  </td>
                  <td className="py-4 px-6">
                    <Link to={`/pokemon-sets/${card.setId}`} className="hover:text-blue-600">
                      {card.Set.name}
                    </Link>
                  </td>
                  <td className="py-4 px-6"><RarityLegend rarity={card.rarity} /></td>
                  <td className="py-4 px-6 text-right font-medium">
                    {card.cardmarket && card.cardmarket.prices ? 
                      `€${card.cardmarket.prices.avg1.toFixed(2)}` : 
                      'N/A'
                    }
                  </td>
                  <td className="py-4 px-6">{card.Set.releaseDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DuplicatesTable;