import React, { useState, useEffect } from 'react';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import { motion } from 'framer-motion';
import { FaXTwitter, FaInstagram, FaDiscord, FaTiktok } from 'react-icons/fa6';

const AboutPage = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <Breadcrumbs />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <motion.section className="text-center mb-16" {...fadeIn}>
              <h1 className="text-5xl font-extrabold text-gray-900 mb-4">Welcome to PULLHAVEN</h1>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                The ultimate platform for TCG collectors, investors, and players
              </p>
            </motion.section>

            <motion.section className="bg-white shadow-sm rounded-lg overflow-hidden mb-16" {...fadeIn}>
              <div className="p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Mission</h2>
                <p className="text-gray-600 mb-4">
                  At PULLHAVEN, we're dedicated to revolutionizing the TCG ecosystem. Our mission is to provide a comprehensive platform that serves both the investment and gameplay aspects of Trading Card Games, empowering collectors, investors, and players with cutting-edge tools and insights.
                </p>
              </div>
            </motion.section>

            <motion.section className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16" {...fadeIn}>
              <div className="bg-white shadow-sm rounded-lg p-8">
                <h2 className="text-3xl font-bold mb-4 text-gray-900">For Collectors & Investors</h2>
                <p className="text-gray-600">
                  We provide advanced analytics, AI-driven market insights, and portfolio management tools to help you make informed decisions and maximize the value of your collection.
                </p>
              </div>
              <div className="bg-white shadow-sm rounded-lg p-8">
                <h2 className="text-3xl font-bold mb-4 text-gray-900">For Players</h2>
                <p className="text-gray-600">
                  Enjoy state-of-the-art deck building tools, strategy insights, and a vibrant community to enhance your gameplay experience and competitive edge.
                </p>
              </div>
            </motion.section>

            <motion.section className="mb-16" {...fadeIn}>
              <h2 className="text-3xl font-bold text-center mb-8 text-gray-900">The PULLHAVEN Advantage</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {[
                  { title: "AI-Powered Market Analysis", description: "Get real-time insights on card values, market trends, and investment opportunities." },
                  { title: "Advanced Portfolio Tracking", description: "Manage and analyze your collection's value over time with sophisticated tracking tools." },
                  { title: "AI Condition Grading", description: "Utilize our AI to get accurate condition assessments, crucial for valuation and trading." },
                  { title: "Investment Strategy Tools", description: "Access data-driven recommendations for optimizing your TCG investment strategy." },
                  { title: "Deck Building & Analysis", description: "Create and refine competitive decks with our AI-assisted building tools and meta analysis." },
                  { title: "Global Trading Platform", description: "Connect with collectors and traders worldwide in our secure, integrated marketplace." },
                ].map((item, index) => (
                  <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
                    <h3 className="text-xl font-bold mb-2 text-gray-900">{item.title}</h3>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                ))}
              </div>
            </motion.section>

            <motion.section className="mb-16" {...fadeIn}>
              <h2 className="text-3xl font-bold text-center mb-8 text-gray-900">Our Innovation Roadmap</h2>
              <div className="relative">
                <div className="absolute h-full w-1 bg-gray-200 left-1/2 transform -translate-x-1/2"></div>
                {[
                  { phase: "Phase 1", title: "Foundation", description: "Launch core features for collection management, deck building, and community engagement.", status: "In Progress" },
                  { phase: "Phase 2", title: "AI Integration", description: "Implement AI-powered market analysis and condition grading systems.", status: "Upcoming" },
                  { phase: "Phase 3", title: "Investment Tools", description: "Introduce advanced portfolio analytics and investment strategy recommendations.", status: "Future" },
                  { phase: "Phase 4", title: "Global Marketplace", description: "Launch an integrated, secure platform for global TCG trading and investments.", status: "Future" },
                ].map((item, index) => (
                  <div key={index} className={`flex ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'} items-center mb-8`}>
                    <div className="w-5/12"></div>
                    <div className="w-2/12 flex justify-center">
                      <div className="bg-blue-500 h-8 w-8 rounded-full border-4 border-white"></div>
                    </div>
                    <div className="w-5/12 bg-white p-4 rounded-lg shadow-sm">
                      <h3 className="text-lg font-bold mb-1 text-gray-900">{item.phase}: {item.title}</h3>
                      <p className="text-sm text-gray-600 mb-1">{item.description}</p>
                      <span className={`text-xs font-semibold ${item.status === 'Completed' ? 'text-green-500' : item.status === 'In Progress' ? 'text-blue-500' : 'text-gray-500'}`}>
                        {item.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </motion.section>

            <motion.section className="bg-white shadow-sm rounded-lg p-8 mb-16" {...fadeIn}>
              <h2 className="text-3xl font-bold mb-4 text-gray-900">Our Commitment</h2>
              <p className="text-gray-600 mb-4">
                At PULLHAVEN, we're committed to fostering a transparent, fair, and thriving ecosystem for TCG enthusiasts of all kinds. We pledge to:
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-600">
                <li>Continuously innovate and improve our platform based on user feedback and market trends</li>
                <li>Maintain the highest standards of data security and user privacy</li>
                <li>Provide unbiased, data-driven insights to support informed decision-making</li>
                <li>Foster a positive, inclusive community that celebrates the passion for TCGs</li>
                <li>Collaborate with industry partners to promote the growth and sustainability of the TCG market</li>
              </ul>
            </motion.section>

            <motion.section className="text-center mb-16" {...fadeIn}>
              <h2 className="text-3xl font-bold mb-4 text-gray-900">Join the PULLHAVEN Community</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
                Whether you're a seasoned investor, an avid collector, or a competitive player, PULLHAVEN is your gateway to the future of TCGs. Connect with us to stay updated on the latest features, market trends, and community events.
              </p>
              <div className="flex justify-center space-x-6">
                <a href="https://x.com/pullhaven" className="text-gray-400 hover:text-gray-600"><FaXTwitter size={32} /></a>
                <a href="https://www.instagram.com/pull.haven/" className="text-gray-400 hover:text-gray-600"><FaInstagram size={32} /></a>
                <a href="https://discord.gg/TTWqq3zc" className="text-gray-400 hover:text-gray-600"><FaDiscord size={32} /></a>
                <a href="https://discord.gg/TTWqq3zc" className="text-gray-400 hover:text-gray-600"><FaTiktok size={32} /></a>
              </div>
            </motion.section>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default AboutPage;