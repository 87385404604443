import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import { ThemeContext } from './themeContext';

const tcgFacts = [
  "The first Magic: The Gathering cards were printed in 1993!",
  "The Pokémon Trading Card Game has printed over 34 billion cards worldwide as of 2021.",
  "Yu-Gi-Oh! was first published in Weekly Shōnen Jump magazine in 1996.",
  "The rarest Magic: The Gathering card, 'Black Lotus', once sold for $511,100.",
  "The Pokémon card 'Pikachu Illustrator' is one of the rarest cards, with only 39 ever made.",
  "The Yu-Gi-Oh! Trading Card Game holds the Guinness World Record for the best-selling trading card game.",
  "Magic: The Gathering has been translated into 11 languages.",
  "The first Pokémon cards were released in Japan in 1996.",
  "In Yu-Gi-Oh!, the 'Egyptian God' cards are among the most powerful in the game.",
  "The artwork for Magic: The Gathering cards has been created by over 350 artists.",
  "The Charizard card from the base set of Pokémon is one of the most iconic TCG cards ever.",
  "Cardfight!! Vanguard, a popular TCG in Japan, was created by the same person who made Yu-Gi-Oh!",
  "Magic: The Gathering was inducted into the National Toy Hall of Fame in 2019.",
  "The Pokémon TCG has featured over 1,000 unique Pokémon species on its cards.",
  "Force of Will, a relatively new TCG, was inspired by Magic: The Gathering and anime culture.",
];

const SignUpPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  const [currentFact, setCurrentFact] = useState(0);
  const navigate = useNavigate();


  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const factRotation = setInterval(() => {
      setCurrentFact((prevFact) => (prevFact + 1) % tcgFacts.length);
    }, 10000); // Rotate facts every 10 seconds

    return () => clearInterval(factRotation);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await fetch('/api/users/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, email, password }),
      });
      const data = await response.json();
      if (response.ok) {
        navigate('/login');
      } else {
        console.error('Registration error:', data); // Log the full error response
        setError(data.error || 'Failed to sign up');
      }
    } catch (err) {
      console.error('Fetch error:', err); // Log any fetch errors
      setError('Failed to sign up. Please try again.');
    }
  };

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="flex min-h-screen">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <div>
                  <h1 className="text-2xl text-center font-bold font-sans">
                    PULLHAVEN <sup className="text-xs align-top">BETA</sup>
                  </h1>
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">Start Your TCG Journey</h2>
                  <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                    Already have an account?{' '}
                    <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                      Sign in here
                    </Link>
                  </p>
                </div>

                <div className="mt-8">
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Player Name
                      </label>
                      <div className="mt-1">
                        <input
                          id="username"
                          name="username"
                          type="text"
                          autoComplete="username"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-s font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                      >
                        {loading ? 'Signing up...' : 'Create Account'}
                      </button>
                    </div>
                  </form>
                  {error && (
                    <div className="mt-3 text-center text-sm text-red-600 dark:text-red-400">
                      {error}
                    </div>
                  )}
                </div>

                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300 dark:border-gray-600" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400">TCG Fun Fact</span>
                    </div>
                  </div>
                  <p className="mt-2 text-xs text-center text-gray-600 dark:text-gray-400 h-8 transition-opacity duration-500">
                    {tcgFacts[currentFact]}
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="./img/landscape_signup.webp"
                alt="TCG Landscape"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white p-12">
                <h3 className="text-4xl font-bold mb-4">Dive into the World of TCGs</h3>
                <ul className="list-disc space-y-2">
                  <li>Access exclusive content for various TCGs<sup className="text-xs align-top">TBA</sup></li>
                  <li>Track your card collections across games<sup className="text-xs align-top">TBA</sup></li>
                  <li>Participate in online tournaments<sup className="text-xs align-top">TBA</sup></li>
                  <li>Connect with players worldwide<sup className="text-xs align-top">TBA</sup></li>
                </ul>
                <div className="mt-8 text-center">
                  <p className="text-lg font-semibold">Trusted by TCG Enthusiasts Everywhere</p>
                   {/* <div className="mt-4 flex justify-center space-x-4">
                    <img className="h-12" src="/path/to/tcg-store-logo.png" alt="TCG Store" />
                    <img className="h-12" src="/path/to/card-company-logo.png" alt="Card Company" />
                    <img className="h-12" src="/path/to/tournament-org-logo.png" alt="Tournament Organizer" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default SignUpPage;