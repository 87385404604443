import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import { ThemeContext } from './themeContext';

const SetCard = ({ set }) => (
  <Link to={`/pokemon-sets/${set.id}`} className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105">
    <div className="h-48 bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-600 flex items-center justify-center p-4">
      <img src={set.logoUrl} alt={`${set.name} logo`} className="max-h-full max-w-full object-contain" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-3">
        <img src={set.symbolUrl} alt={`${set.name} symbol`} className="w-8 h-8 mr-3" />
        <h3 className="text-xl font-bold text-gray-800 dark:text-white">{set.name}</h3>
      </div>
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">Series: {set.series}</p>
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">Cards: {set.total}</p>
      <p className="text-sm text-gray-600 dark:text-gray-400">Released: {new Date(set.releaseDate).toLocaleDateString()}</p>
    </div>
  </Link>
);

const SetsOverviewPage = () => {
  const [sets, setSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [cardData, setCardData] = useState(null);
  const seriesRefs = useRef({});
  const location = useLocation();
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const fetchSets = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || '/api/sets';
        console.log('Fetching sets from:', apiUrl);
        const response = await axios.get(apiUrl);
        console.log('Response received:', response);
        setSets(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching sets:', err);
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error response data:', err.response.data);
          console.error('Error response status:', err.response.status);
          console.error('Error response headers:', err.response.headers);
        } else if (err.request) {
          // The request was made but no response was received
          console.error('Error request:', err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', err.message);
        }
        setError('Failed to load sets: ' + err.message);
        setLoading(false);
      }
    };

    fetchSets();
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (location.hash && sets.length > 0) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, sets]);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const groupSetsBySeries = (sets) => {
    const groups = {};
    sets.forEach((set) => {
      const seriesName = set.series;
      if (!groups[seriesName]) {
        groups[seriesName] = [];
      }
      groups[seriesName].push(set);
    });
    return groups;
  };

  if (loading) return <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
  </div>;
  if (error) return <div className="text-center text-red-500 text-xl mt-8">Error: {error}</div>;


  const groupedSets = groupSetsBySeries(sets);

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <Breadcrumbs cardData={cardData} />
          <div className="container mx-auto px-4 py-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            
            <nav className="mb-8 bg-white dark:bg-gray-800 p-4 rounded-xl shadow-md top-0 z-10">
              <ul className="flex flex-wrap justify-center gap-4">
                {Object.keys(groupedSets).map(series => (
                  <li key={series}>
                    <a 
                      href={`#${series.replace(/\s+/g, '-').toLowerCase()}`} 
                      className="px-4 py-2 bg-blue-100 text-blue-700 rounded-full hover:bg-blue-200 transition-colors duration-200"
                    >
                      {series}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>

            {Object.keys(groupedSets).map(series => (
              <div 
                key={series} 
                id={series.replace(/\s+/g, '-').toLowerCase()} 
                ref={el => seriesRefs.current[series] = el} 
                className="mb-16 scroll-mt-24"
              >
                <h2 className="text-3xl font-semibold mb-8 text-gray-700 dark:text-gray-300 border-b-2 border-gray-300 dark:border-gray-700 pb-2">{series}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                  {groupedSets[series].map(set => (
                    <SetCard key={set.id} set={set} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default SetsOverviewPage;