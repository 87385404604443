import React, { useState, useEffect } from 'react';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';

const ImprintPage = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <Breadcrumbs />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <h1 className="text-3xl font-bold mb-8">Imprint</h1>
            
            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Information pursuant to § 5 TMG</h2>
              <p>PULLHAVEN</p>
              <p>Stengelestraße 23</p>
              <p>22111 Hamburg</p>
              <p>Germany</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Contact</h2>
              <p>Email: info@pullhaven.com</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Represented by</h2>
              <p>Vladimir Kusnezow, CEO</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">VAT ID</h2>
              <p>VAT identification number according to §27a of the German VAT act:</p>
              <p>DE346325522</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Responsible for content according to § 55 Abs. 2 RStV</h2>
              <p>Vladimir Kusnezow</p>
              <p>PULLHAVEN</p>
              <p>Stengelestraße 23</p>
              <p>22111 Hamburg</p>
              <p>Germany</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">EU dispute resolution</h2>
              <p>The European Commission provides a platform for online dispute resolution (OS): <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">https://ec.europa.eu/consumers/odr/</a></p>
              <p>You can find our e-mail address in the imprint above.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Consumer dispute resolution/universal dispute resolution body</h2>
              <p>We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Disclaimer</h2>
              <h3 className="text-lg font-semibold mb-2">Liability for contents</h3>
              <p>The contents of our pages have been created with utmost care. However, we cannot guarantee the contents' accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this context, please note that we are accordingly not obliged to monitor merely the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).</p>
              
              <h3 className="text-lg font-semibold mt-4 mb-2">Liability for links</h3>
              <p>Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately.</p>
              
              <h3 className="text-lg font-semibold mt-4 mb-2">Copyright</h3>
              <p>Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are allowed only for private use, so must not serve either directly or indirectly for earnings. Unauthorized utilization of copyrighted works is punishable (§ 106 of the copyright law).</p>
            </section>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default ImprintPage;