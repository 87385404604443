import React, {useContext} from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from '../../themeContext.js';

const Breadcrumbs = ({ cardData, setData }) => {
  const location = useLocation();
  const { isDarkMode } = useContext(ThemeContext);

  let breadcrumbItems = [
    { label: 'All TCGs', href: '/' },
    { label: 'Pokemon Sets', href: '/pokemon-sets' },
  ];

  if (cardData && cardData.Set) {
    breadcrumbItems = [
      ...breadcrumbItems,
      { 
        label: `${cardData.Set.id.toUpperCase()}: ${cardData.Set.name}`, 
        href: `/pokemon-sets/${cardData.Set.id}` 
      },
      { label: cardData.name.en, href: '#' },
    ];
  } else if (setData) {
    breadcrumbItems = [
      ...breadcrumbItems,
      { 
        label: `${setData.id.toUpperCase()}: ${setData.name}`, 
        href: '#' 
      },
    ];
  }

  return (
    <nav className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border overflow-hidden`}>
      <div className="max-w-7xl mx-auto px-4 py-3 sm:px-6 lg:px-8">
        <ol className="flex items-center space-x-1 text-sm sm:text-base">
          {breadcrumbItems.map((item, index) => (
            <li key={index} className="flex items-center">
              {index > 0 && (
                <FaChevronRight className={`flex-shrink-0 h-4 w-4 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'} mx-1`} aria-hidden="true" />
              )}
              <Link
                to={item.href}
                className={`hover:text-blue-600 transition-colors duration-200 ${
                  index === breadcrumbItems.length - 1
                    ? `font-semibold ${isDarkMode ? 'text-white' : 'text-gray-800'}`
                    : `${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`
                }`}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;