import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Search, Filter, ChevronDown, ChevronUp, Grid, List } from 'lucide-react';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import { ThemeContext } from './themeContext';
import TypeIcon from './components/card/typeIcon.tsx';
import RarityLegend from './components/card/rarityLegend.tsx';
import CardList from './components/set/cardList.tsx';
import FilterComponent from './components/filtercomponent.tsx';

const CardSearch = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState('card'); // 'card' or 'list'
  const [filters, setFilters] = useState({
    types: [],
    rarities: [],
    sets: [],
    energyType: '',
    hp: { min: 0, max: 300 },
    releaseDate: { start: '', end: '' },
    cardType: '',
    trainerType: '',
    stage: '',
    ownedCards: false,
    legalCards: true,
    ability: '',
    attackDamage: { min: 0, max: 300 },
    weakness: '',
    priceRange: { min: 0, max: 1000 },
  });
  const [expandedFilters, setExpandedFilters] = useState({
    rarities: false,
    cardType: false,
    stage: false,
    trainerType: false,
  });

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const handleSearch = async () => {
    setLoading(true);
    // Implement your search logic here
    // This is a placeholder for demonstration
    const response = await fetch(`/api/search?q=${searchTerm}`);
    const data = await response.json();
    setCards(data);
    setLoading(false);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const toggleFilterExpansion = (filterType) => {
    setExpandedFilters(prev => ({
      ...prev,
      [filterType]: !prev[filterType]
    }));
  };

  const ListView = ({ cards }) => (
    <div className="space-y-4">
      {cards.map(card => (
        <Link to={`/card/${card.id}`} key={card.id} className="block">
          <div className={`flex items-center p-4 rounded-lg ${isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-100'} transition-colors duration-200`}>
            <img src={card.images.small} alt={card.name} className="w-20 h-28 object-contain mr-4" />
            <div>
              <h3 className={`font-bold text-lg mb-1 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{card.name}</h3>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{card.set.name}</p>
              <div className="mt-2 flex items-center">
                <RarityLegend rarity={card.rarity} />
                <span className={`ml-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {card.cardmarket && card.cardmarket.prices ? `€${card.cardmarket.prices.avg1.toFixed(2)}` : 'N/A'}
                </span>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden ">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4 py-8">            
            <div className="flex flex-col md:flex-row gap-6">
              {/* Search and Results Area */}
              <div className="w-full md:w-3/4">
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mb-6 rounded-xl">
                    <div className="flex flex-col mb-4">
                    <div className="flex items-center">
                        <input
                            type="text"
                            placeholder="Search for cards..."
                            className="flex-grow p-2 border-2 border-r-0 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button
                            onClick={handleSearch}
                            className="bg-blue-500 text-white p-2 rounded-r-lg hover:bg-blue-600 transition-colors duration-200 border-2 border-blue-500"
                        >
                            <Search size={24} />
                        </button>
                        </div>
                        <div className="flex mt-2 justify-between items-center">
                            <div className="flex items-center space-x-4">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={filters.ownedCards}
                                    onChange={(e) => handleFilterChange('ownedCards', e.target.checked)}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="ml-2 text-gray-700 dark:text-gray-300">Owned Cards</span>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={filters.legalCards}
                                    onChange={(e) => handleFilterChange('legalCards', e.target.checked)}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="ml-2 text-gray-700 dark:text-gray-300">Legal Cards</span>
                            </div>
                        </div>
                        <div className="flex">
                            <button
                                onClick={() => setViewMode('card')}
                                className={`mr-2 p-2 rounded ${viewMode === 'card' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                            >
                                <Grid size={20} />
                            </button>
                            <button
                                onClick={() => setViewMode('list')}
                                className={`p-2 rounded ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                            >
                                <List size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <p className="text-center text-gray-600 dark:text-gray-400">Loading...</p>
                    ) : (
                    viewMode === 'card' ? (
                        <CardList cards={cards} />
                    ) : (
                        <ListView cards={cards} />
                    )
                )}
            </div>
        </div>

        {/* Filters Area */}
        <div className="w-full md:w-1/4">
              <FilterComponent
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleSearch={handleSearch}
              />
            </div>
        </div>
    </div>
    <Footer />
</main>
</div>
</div>
);
};
                        
                        
export default CardSearch;
