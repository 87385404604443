import React, { useState, useEffect, useContext } from 'react';
import { Search, Mail, Phone, MessageCircle, FileText, HelpCircle, ChevronRight } from 'lucide-react';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import { ThemeContext } from './themeContext';

const SupportPage: React.FC = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const categories = [
    { icon: <FileText className="w-6 h-6" />, title: 'Account & Billing', description: 'Manage your account settings and billing information' },
    { icon: <HelpCircle className="w-6 h-6" />, title: 'Using the Platform', description: 'Learn how to use our platform effectively' },
    { icon: <MessageCircle className="w-6 h-6" />, title: 'Community & Forum', description: 'Connect with other users and share experiences' },
  ];

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 dark:text-white">How can we help you?</h1>
            
            {/* Search Bar */}
            <div className="max-w-3xl mx-auto mb-12">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search for help..."
                  className="w-full pl-12 pr-4 py-3 rounded-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>
            </div>

            {/* Help Categories */}
            <div className="grid md:grid-cols-3 gap-8 mb-12">
              {categories.map((category, index) => (
                <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
                  <div className="flex items-center mb-4">
                    <div className="bg-blue-100 dark:bg-blue-900 rounded-full p-3 mr-4">
                      {category.icon}
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-white">{category.title}</h3>
                  </div>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">{category.description}</p>
                  <a href="#" className="text-blue-500 hover:text-blue-600 flex items-center">
                    Learn more <ChevronRight size={16} className="ml-1" />
                  </a>
                </div>
              ))}
            </div>

            {/* Contact Options */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-8">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Still need help?</h2>
              <div className="grid md:grid-cols-3 gap-8">
                <a href="#" className="flex items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-300">
                  <Mail className="w-6 h-6 mr-3 text-blue-500" />
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-white">Email Us</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">Get a response within 24 hours</p>
                  </div>
                </a>
                <a href="#" className="flex items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-300">
                  <Phone className="w-6 h-6 mr-3 text-green-500" />
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-white">Call Us</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">Mon-Fri from 8am to 5pm</p>
                  </div>
                </a>
                <a href="#" className="flex items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-300">
                  <MessageCircle className="w-6 h-6 mr-3 text-purple-500" />
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-white">Live Chat</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">Chat with our support team</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default SupportPage;