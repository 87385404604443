import React, { useState, useEffect, useContext } from 'react';
import { Book, Layers, Flame, Tag, Trophy, Users, Eye, Ban, Image, CreditCard, Sun, Moon, ChevronLeft, ChevronRight, Plus, Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ThemeContext } from './themeContext';

// Import TCG logos
import digimonLogo from './img/digi/digimon_card_game.webp';
import magicLogo from './img/magic/magic_card_game.png';
import onePieceLogo from './img/op/one_piece_card_game.webp';
import pokemonLogo from './img/pkm/pokemon_card_game.png';
import yugiohLogo from './img/ygo/one_piece_card_game.webp';

const tcgOptions = [
  { id: 'pokemon', logo: pokemonLogo, alt: 'Pokemon TCG', active: true },
  { id: 'yugioh', logo: yugiohLogo, alt: 'Yu-Gi-Oh! TCG', active: false },
  { id: 'magic', logo: magicLogo, alt: 'Magic: The Gathering', active: false, darkModeAdjust: true },
  { id: 'digimon', logo: digimonLogo, alt: 'Digimon TCG', active: false },
  { id: 'onepiece', logo: onePieceLogo, alt: 'One Piece TCG', active: false, darkModeAdjust: true },
];

interface SidebarProps {
  isMobile: boolean;
  toggleMobileSidebar: () => void;
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isMobile, toggleMobileSidebar, isOpen }) => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (isMobile) {
      setIsExpanded(isOpen);
    }
  }, [isMobile, isOpen]);

  const toggleSidebar = () => {
    if (!isMobile) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleAddClick = (item: string) => {
    console.log(`Add to ${item}`);
    // Implement the add functionality here
  };
  const menuItems = [
    { icon: <Book size={24} />, label: 'Collection Manager', section: 'LIBRARY', hasAdd: true, to: '/collection' },
    { icon: <Layers size={24} />, label: 'Deck Builder', section: 'LIBRARY', hasAdd: true, to: '/deck-builder' },
    { icon: <Flame size={24} />, label: 'Top Decks', section: 'BROWSE', to: '/top-decks' },
    { icon: <Search size={24} />, label: 'Card Search', section: 'BROWSE', to: '/pokemon-search' },
    { icon: <Tag size={24} />, label: 'Sets', section: 'BROWSE', to: '/sets' },
    { icon: <Tag size={24} />, label: 'Themes', section: 'BROWSE', to: '/themes' },
    { icon: <Trophy size={24} />, label: 'Tournaments', section: 'BROWSE', to: '/tournaments' },
    { icon: <Users size={24} />, label: 'Community', section: 'BROWSE', to: '/community' },
    { icon: <Eye size={24} />, label: 'Spoiler', section: 'DATABASE', to: '/spoilers' },
    { icon: <Ban size={24} />, label: 'Banned Cards', section: 'DATABASE', to: '/banned-cards' },
    { icon: <Image size={24} />, label: 'Alternative Artworks', section: 'DATABASE', to: '/alternative-artworks' },
  ];

  return (
    <aside
      className={`bg-gray-900 text-white h-screen ${
        isExpanded ? 'w-80' : 'w-20'
      } transition-all duration-300 ease-in-out ${
        isMobile ? 'fixed' : 'relative'
      } z-20 flex flex-col`}
      style={{ left: isMobile && !isOpen ? '-100%' : '0' }}
    >
      <div className="p-6 flex justify-between items-center">
        {isExpanded && (
          <h1 className="text-2xl font-bold font-sans">
            PULLHAVEN <sup className="text-xs align-top">ALPHA</sup>
          </h1>
        )}

        <button
          onClick={isMobile ? toggleMobileSidebar : toggleSidebar}
          className="text-white p-2 rounded-full hover:bg-gray-800"
        >
          {isMobile ? (
            <ChevronLeft size={28} />
          ) : (
            isExpanded ? <ChevronLeft size={28} /> : <ChevronRight size={28} />
          )}
        </button>
      </div>

      {isMobile ? (
        <>
          <div className="px-6 py-4">
            <div className="relative mb-6">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search"
                className="pl-12 pr-4 py-3 w-full rounded-full border border-gray-700 bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-lg"
              />
            </div>
          </div>
          <div className="flex-grow overflow-y-auto hide-scrollbar">
            <div className="px-6 py-4">
              <div className="flex space-x-4 mb-6 justify-center">
                {tcgOptions.map((tcg) => (
                  <div key={tcg.id} className="relative group">
                    {tcg.active ? (
                      <Link to="/pokemon-sets">
                        <img 
                          src={tcg.logo} 
                          alt={tcg.alt} 
                          className={`h-10 w-10 object-contain ${tcg.darkModeAdjust ? 'dark:invert' : ''}`}
                        />
                      </Link>
                    ) : (
                      <img 
                        src={tcg.logo} 
                        alt={tcg.alt} 
                        className={`h-10 w-10 object-contain filter grayscale opacity-50 cursor-not-allowed ${tcg.darkModeAdjust ? 'dark:invert' : ''}`}
                      />
                    )}
                    {!tcg.active && (
                      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                        Coming Soon
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="relative group mb-6">
                <select className="appearance-none bg-gray-800 text-white border-none w-full p-3 rounded text-lg cursor-pointer">
                  <option value="de" disabled>🇩🇪 DE</option>
                  <option value="en">🇬🇧 EN</option>
                  <option value="fr" disabled>🇫🇷 FR</option>
                </select>
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
                  Coming Soon
                </div>
              </div>
              <Link
                to="/login"
                className="bg-gray-700 text-white px-6 py-3 rounded hover:bg-gray-600 transition-colors duration-200 w-full mb-4 text-lg inline-block text-center"
              >
                Sign In
              </Link>
              <Link
                to="/signup"
                className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 transition-colors duration-200 w-full mb-6 text-lg inline-block text-center"
              >
                Register
              </Link>
              <button
                onClick={toggleDarkMode}
                className="p-3 rounded-full hover:bg-gray-800 w-full flex items-center justify-center text-lg mb-6"
              >
                {isDarkMode ? (
                  <><Sun className="h-7 w-7 text-gray-400 mr-3" /> Light Mode</>
                ) : (
                  <><Moon className="h-7 w-7 text-gray-400 mr-3" /> Dark Mode</>
                )}
              </button>
            </div>
            <nav>
              {menuItems.reduce((acc, item, index) => {
                if (index === 0 || item.section !== menuItems[index - 1].section) {
                  acc.push(
                    <div key={item.section} className={`px-6 py-3 text-base text-gray-400 ${!isExpanded && 'hidden'}`}>
                      {item.section}
                    </div>
                  );
                }
                acc.push(
                  <div key={item.label} className="flex items-center px-6 py-4 hover:bg-gray-800">
                    <Link
                      to={item.to}
                      className="flex items-center flex-grow text-lg"
                    >
                      {item.icon}
                      {isExpanded && <span className="ml-4">{item.label}</span>}
                    </Link>
                    {isExpanded && item.hasAdd && (
                      <button
                        onClick={() => handleAddClick(item.label)}
                        className="ml-2 p-2 rounded-full hover:bg-gray-700"
                      >
                        <Plus size={24} className="text-gray-400" />
                      </button>
                    )}
                  </div>
                );
                return acc;
              }, [])}
            </nav>
          </div>
        </>
      ) : (
        <nav className="flex-grow overflow-y-auto hide-scrollbar">
          {menuItems.reduce((acc, item, index) => {
            if (index === 0 || item.section !== menuItems[index - 1].section) {
              acc.push(
                <div key={item.section} className={`px-6 py-3 text-base text-gray-400 ${!isExpanded && 'hidden'}`}>
                  {item.section}
                </div>
              );
            }
            acc.push(
              <div key={item.label} className="flex items-center px-6 py-4 hover:bg-gray-800">
                <Link
                  to={item.to}
                  className="flex items-center flex-grow text-lg"
                >
                  {item.icon}
                  {isExpanded && <span className="ml-4">{item.label}</span>}
                </Link>
                {isExpanded && item.hasAdd && (
                  <button
                    onClick={() => handleAddClick(item.label)}
                    className="ml-2 p-2 rounded-full hover:bg-gray-700"
                  >
                    <Plus size={24} className="text-gray-400" />
                  </button>
                )}
              </div>
            );
            return acc;
          }, [])}
        </nav>
      )}
    </aside>
  );
};

export default Sidebar;