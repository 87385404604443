import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip } from 'chart.js';
import { ThemeContext } from '../../themeContext.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const PriceAnalysis = ({ priceData, priceTimeframe, setPriceTimeframe }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: isDarkMode ? 'rgba(75, 85, 99, 0.3)' : 'rgba(209, 213, 219, 0.5)',

        },
        ticks: {
          callback: function(value, index, values) {
            const date = new Date(this.getLabelForValue(value));
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          },
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 6,
          color: isDarkMode ? '#9ca3af' : '#4b5563',
        },
      },
      y: {
        grid: {
          color: isDarkMode ? 'rgba(75, 85, 99, 0.3)' : 'rgba(209, 213, 219, 0.5)',
        },
        ticks: {
          callback: function(value, index, values) {
            return '$' + value;
          },
          color: isDarkMode ? '#9ca3af' : '#4b5563',
        },
      },
    },
  };

  return (
    <div className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border rounded-xl overflow-hidden mt-4`}>
      <div className="p-4 sm:p-6">
        <div className="h-64 sm:h-80 mb-4">
          {priceData && <Line data={{
            ...priceData,
            datasets: [{
              ...priceData.datasets[0],
              borderColor: isDarkMode ? '#60a5fa' : '#3b82f6',
              backgroundColor: isDarkMode ? 'rgba(96, 165, 250, 0.1)' : 'rgba(59, 130, 246, 0.1)',
            }],
          }} options={chartOptions} />}
        </div>
        <div className="grid grid-cols-4 gap-2 mb-6">
          {['1M', '6M', '1Y', 'MAX'].map((timeframe) => (
            <button 
              key={timeframe}
              onClick={() => setPriceTimeframe(timeframe)} 
              className={`py-2 px-1 sm:px-4 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                priceTimeframe === timeframe 
                  ? isDarkMode
                    ? 'bg-blue-600 text-white'
                    : 'bg-blue-500 text-white'
                  : isDarkMode
                    ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {timeframe}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-lg`}>
            <h3 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Price Difference</h3>
            <p className="text-2xl font-bold text-green-500">+12.5%</p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Compared to last month</p>
          </div>
          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-lg`}>
            <h3 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Value Projection</h3>
            <p className="text-2xl font-bold text-blue-500">HOLD</p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Estimated 5% increase over next 6 months</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceAnalysis;