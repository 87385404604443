import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import TypeIcon from './card/typeIcon.tsx';

interface FilterProps {
  filters: {
    types: string[];
    rarities: string[];
    sets: string[];
    energyType: string;
    hp: { min: number; max: number };
    releaseDate: { start: string; end: string };
    cardType: string;
    trainerType: string;
    stage: string;
    ownedCards: boolean;
    legalCards: boolean;
    ability: string;
    attackDamage: { min: number; max: number };
    weakness: string;
    priceRange: { min: number; max: number };
  };
  handleFilterChange: (filterType: string, value: any) => void;
  handleSearch: () => void;
}

const FilterComponent: React.FC<FilterProps> = ({ filters, handleFilterChange, handleSearch }) => {
  const [expandedFilters, setExpandedFilters] = useState({
    rarities: false,
    cardType: false,
    stage: false,
    trainerType: false,
  });

  const toggleFilterExpansion = (filterType: string) => {
    setExpandedFilters(prev => ({
      ...prev,
      [filterType]: !prev[filterType as keyof typeof expandedFilters]
    }));
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow p-6">
      {/* Types Filter */}
      <div className="mb-4 grid grid-cols-3 gap-2">
        {['Grass', 'Fire', 'Water', 'Lightning', 'Psychic', 'Fighting', 'Darkness', 'Metal', 'Fairy', 'Dragon', 'Colorless'].map(type => (
          <button
            key={type}
            onClick={() => handleFilterChange('types', filters.types.includes(type) ? filters.types.filter(t => t !== type) : [...filters.types, type])}
            className={`p-2 rounded-full flex items-center justify-center ${filters.types.includes(type) ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300'}`}
          >
            <TypeIcon type={type} className="w-6 h-6" />
          </button>
        ))}
      </div>

      {/* Set Filter */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search sets..."
          className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          onChange={(e) => handleFilterChange('sets', e.target.value)}
        />
      </div>

      {/* Rarities Filter */}
      <div className="mb-4">
        <button
          onClick={() => toggleFilterExpansion('rarities')}
          className="flex justify-between items-center w-full text-left font-semibold text-gray-700 dark:text-gray-300"
        >
          Rarities
          {expandedFilters.rarities ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {expandedFilters.rarities && (
          <div className="mt-2 space-y-2">
            {['Common', 'Uncommon', 'Rare', 'Rare Holo', 'Ultra Rare', 'Secret Rare'].map(rarity => (
              <label key={rarity} className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.rarities.includes(rarity)}
                  onChange={() => {
                    const updatedRarities = filters.rarities.includes(rarity)
                      ? filters.rarities.filter(r => r !== rarity)
                      : [...filters.rarities, rarity];
                    handleFilterChange('rarities', updatedRarities);
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">{rarity}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Card Type Filter */}
      <div className="mb-4">
        <button
          onClick={() => toggleFilterExpansion('cardType')}
          className="flex justify-between items-center w-full text-left font-semibold text-gray-700 dark:text-gray-300"
        >
          Card Type
          {expandedFilters.cardType ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {expandedFilters.cardType && (
          <div className="mt-2 space-y-2">
            {['Pokemon', 'Trainer', 'Item', 'Energy'].map(type => (
              <label key={type} className="flex items-center">
                <input
                  type="radio"
                  checked={filters.cardType === type}
                  onChange={() => handleFilterChange('cardType', type)}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">{type}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Stage Filter */}
      <div className="mb-4">
        <button
          onClick={() => toggleFilterExpansion('stage')}
          className="flex justify-between items-center w-full text-left font-semibold text-gray-700 dark:text-gray-300"
        >
          Stage
          {expandedFilters.stage ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {expandedFilters.stage && (
          <div className="mt-2 space-y-2">
            {['Basic', 'Stage 1', 'Stage 2', 'EX', 'GX', 'V', 'VMAX', 'VStar'].map(stage => (
              <label key={stage} className="flex items-center">
                <input
                  type="radio"
                  checked={filters.stage === stage}
                  onChange={() => handleFilterChange('stage', stage)}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">{stage}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Trainer-Type Filter */}
      <div className="mb-4">
        <button
          onClick={() => toggleFilterExpansion('trainerType')}
          className="flex justify-between items-center w-full text-left font-semibold text-gray-700 dark:text-gray-300"
        >
          Trainer-Type
          {expandedFilters.trainerType ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {expandedFilters.trainerType && (
          <div className="mt-2 space-y-2">
            {['Supporter', 'Item', 'Tool', 'Stadium', 'Pokémon Tool', 'ACE Spec'].map(type => (
              <label key={type} className="flex items-center">
                <input
                  type="radio"
                  checked={filters.trainerType === type}
                  onChange={() => handleFilterChange('trainerType', type)}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">{type}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Ability Filter */}
      <div className="mb-4">
        <label className="block font-semibold text-gray-700 dark:text-gray-300 mb-2">Ability</label>
        <input
          type="text"
          placeholder="Search abilities..."
          className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          value={filters.ability}
          onChange={(e) => handleFilterChange('ability', e.target.value)}
        />
      </div>

      {/* Attack Damage Range */}
      <div className="mb-4">
        <label className="block font-semibold text-gray-700 dark:text-gray-300 mb-2">Attack Damage</label>
        <div className="flex items-center">
          <input
            type="number"
            value={filters.attackDamage.min}
            onChange={(e) => handleFilterChange('attackDamage', { ...filters.attackDamage, min: parseInt(e.target.value) })}
            className="w-1/2 p-2 border rounded-l dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Min"
          />
          <input
            type="number"
            value={filters.attackDamage.max}
            onChange={(e) => handleFilterChange('attackDamage', { ...filters.attackDamage, max: parseInt(e.target.value) })}
            className="w-1/2 p-2 border rounded-r dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Max"
          />
        </div>
      </div>

      {/* Weakness Filter */}
      <div className="mb-4">
        <label className="block font-semibold text-gray-700 dark:text-gray-300 mb-2">Weakness</label>
        <select
          value={filters.weakness}
          onChange={(e) => handleFilterChange('weakness', e.target.value)}
          className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <option value="">Any</option>
          {['Grass', 'Fire', 'Water', 'Lightning', 'Psychic', 'Fighting', 'Darkness', 'Metal', 'Fairy', 'Dragon'].map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
      </div>

      {/* Price Range Filter */}
      <div className="mb-4">
        <label className="block font-semibold text-gray-700 dark:text-gray-300 mb-2">Price Range (€)</label>
        <div className="flex items-center">
          <input
            type="number"
            value={filters.priceRange.min}
            onChange={(e) => handleFilterChange('priceRange', { ...filters.priceRange, min: parseFloat(e.target.value) })}
            className="w-1/2 p-2 border rounded-l dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Min"
          />
          <input
            type="number"
            value={filters.priceRange.max}
            onChange={(e) => handleFilterChange('priceRange', { ...filters.priceRange, max: parseFloat(e.target.value) })}
            className="w-1/2 p-2 border rounded-r dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Max"
          />
        </div>
      </div>

      {/* HP Range */}
      <div className="mb-4">
        <label className="block font-semibold text-gray-700 dark:text-gray-300 mb-2">HP Range</label>
        <div className="flex items-center">
          <input
            type="number"
            value={filters.hp.min}
            onChange={(e) => handleFilterChange('hp', { ...filters.hp, min: parseInt(e.target.value) })}
            className="w-1/2 p-2 border rounded-l dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Min"
          />
          <input
            type="number"
            value={filters.hp.max}
            onChange={(e) => handleFilterChange('hp', { ...filters.hp, max: parseInt(e.target.value) })}
            className="w-1/2 p-2 border rounded-r dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Max"
          />
        </div>
      </div>

      {/* Release Date Range */}
      <div className="mb-4">
        <label className="block font-semibold text-gray-700 dark:text-gray-300 mb-2">Release Date</label>
        <div className="flex items-center">
          <input
            type="date"
            value={filters.releaseDate.start}
            onChange={(e) => handleFilterChange('releaseDate', { ...filters.releaseDate, start: e.target.value })}
            className="w-1/2 p-2 border rounded-l dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
          <input
            type="date"
            value={filters.releaseDate.end}
            onChange={(e) => handleFilterChange('releaseDate', { ...filters.releaseDate, end: e.target.value })}
            className="w-1/2 p-2 border rounded-r dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      <button
        onClick={handleSearch}
        className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors duration-200"
      >
        Apply Filters
      </button>
    </div>
  );
};

export default FilterComponent;