import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import { ThemeContext } from './themeContext';
import { Shield, AlertTriangle } from 'lucide-react';
import CardList from './components/set/cardList.tsx';

const BannedCardsPage: React.FC = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  const [bannedCards, setBannedCards] = useState({
    Standard: [],
    Expanded: [],
    Unlimited: []
  });

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const fetchBannedCards = async () => {
      const bannedCardIds = {
        Standard: [],
        Expanded: [
          "bw3-67", "sm10-165", "xy9-98", "sm6-83", "xy7-74", "bw9-101", "xy7-75", 
          "sm12-194", "sm115-58", "sm10-178", "xy4-99", "sm35-45", "xy5-133", "xy2-23", 
          "sm10-78", "sm5-114", "xy9-109", "xy1-124", "sm11-206", "bw5-62", "swsh2-165", 
          "xy6-77", "sm8-90", "sm8-91"
        ],
        Unlimited: []
      };
    
      const fetchedCards = {
        Standard: [],
        Expanded: [],
        Unlimited: []
      };
    
      for (const [format, cardIds] of Object.entries(bannedCardIds)) {
        for (const cardId of cardIds) {
          try {
            const response = await axios.get(`/api/cards/${cardId}`);
            const card = response.data;
            card.images = {
              small: `/img/pokemon_cards_en/images/${card.id}.webp`,
              large: `/img/pokemon_cards_en/images/${card.id}.webp`
            };
            // Ensure the set information is correctly structured
            if (card.set) {
              card.set = {
                ...card.set,
                id: card.set.id || card.setId // Fallback to card.setId if set.id is not available
              };
            } else {
              card.set = { id: card.setId };
            }
            fetchedCards[format].push(card);
          } catch (error) {
            console.error(`Error fetching card ${cardId}:`, error);
          }
        }
      }
    
      setBannedCards(fetchedCards);
    };

    fetchBannedCards();
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 dark:text-white">
              Pokémon TCG Banned Card List
            </h1>

            {Object.entries(bannedCards).map(([format, cards]) => (
              <div key={format} className="mb-12">
                <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-300 flex items-center">
                  <Shield className="mr-2" /> {format}
                </h2>
                <div className="bg-white dark:bg-gray-800 rounded shadow-md p-6">
                  {cards.length > 0 ? (
                    <CardList cards={cards} />
                  ) : (
                    <p className="text-green-600 dark:text-green-400 flex items-center">
                      <AlertTriangle className="mr-2" />
                      No cards are currently banned in the {format} format.
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default BannedCardsPage;