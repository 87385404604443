// SingleArtistPage.tsx
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import CardList from './components/set/cardList.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import { ThemeContext } from './themeContext';
import { Loader } from 'lucide-react';

const SingleArtistPage: React.FC = () => {
  const { artistName } = useParams<{ artistName: string }>();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const fetchArtistCards = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/artists/${encodeURIComponent(artistName)}`);
        console.log('Received artist data:', response.data);
        // Ensure all cards have a 'set' property
        const processedCards = response.data.map(card => ({
          ...card,
          set: card.set || { id: 'unknown', name: 'Unknown Set', printedTotal: 0 }
        }));
        setCards(processedCards);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching artist cards:', error);
        setError('Failed to load artist cards');
        setLoading(false);
      }
    };

    fetchArtistCards();
  }, [artistName]);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <Breadcrumbs artistName={artistName} />
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 dark:text-white">
              {artistName}
            </h1>
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <Loader className="animate-spin h-8 w-8 text-blue-500" />
              </div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : (
              <>
                <p className="text-center text-gray-600 dark:text-gray-400 mb-8">
                  Total cards: {cards.length}
                </p>
                <CardList cards={cards} />
              </>
            )}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default SingleArtistPage;