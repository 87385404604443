import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

interface Card {
  id: string;
  name: {
    en: string;
  };
  number: string;
  set?: {
    id: string;
    name: string;
    printedTotal: number;
  };
  Set?: {
    id: string;
    name: string;
    printedTotal: number;
  };
  rarity: string;
  supertype: string;
  subtypes?: string[];
  cardmarket?: {
    prices: {
      avg1: number;
    };
  };
  images: {
    small: string;
  };
}

interface RelatedCardsProps {
  cardData: Card;
}

const RelatedCards: React.FC<RelatedCardsProps> = ({ cardData }) => {
  const [relatedCards, setRelatedCards] = useState<Card[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRelatedCards = async () => {
      if (!cardData) {
        setError('Card data is missing');
        return;
      }

      const setInfo = cardData.Set || cardData.set;

      if (!setInfo || !setInfo.id) {
        setError('Set information is missing');
        return;
      }

      try {
        const url = `/api/cards/set/${setInfo.id}/related/${cardData.id}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setRelatedCards(data);
      } catch (error) {
        console.error('Error fetching related cards:', error);
        setError(`Failed to fetch related cards: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    };

    fetchRelatedCards();
  }, [cardData]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const setInfo = cardData?.Set || cardData?.set;

  if (!setInfo) {
    return <div>No set information available for this card.</div>;
  }

  return (
    <div className="mt-8">
      <div className="mb-8">
      <h3 className="text-2xl font-semibold mb-4">More Cards from {setInfo.name}</h3>
          {relatedCards.length > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {relatedCards.map((card) => (
              <Link key={card.id} to={`/pokemon-sets/${setInfo.id}/${card.id}`} className="group">
                <div className="relative overflow-hidden rounded-xl shadow-lg">
                <img src={`/img/pokemon_cards_en/images/${card.id}.webp`} alt={card.name.en} className="w-full" />
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-all duration-300 flex flex-col justify-end p-4 opacity-0 group-hover:opacity-100">
                    <div className="text-white">
                      <p className="font-bold text-sm mb-1">{card.name.en}</p>
                      <p className="text-xs mb-1">{`${card.supertype}${card.subtypes ? ' - ' + card.subtypes.join(', ') : ''}`}</p>
                      <p className="text-xs mb-1">{`Rarity: ${card.rarity}`}</p>
                      {card.cardmarket && card.cardmarket.prices && (
                        <p className="text-xs">
                          Value: €{card.cardmarket.prices.avg1.toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <p className="mt-2 text-center text-sm text-grey-600">
                  {`${card.name.en} ${card.number}/${setInfo.printedTotal || 'Unknown'}`}
                </p>
              </Link>
            ))}
          </div>
        ) : (
          <p>No neighboring cards found.</p>
        )}
      </div>
    </div>
  );
};

export default RelatedCards;