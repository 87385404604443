import React from 'react';
import { FaInstagram, FaDiscord, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Footer = () => (
  <footer className="bg-gray-900 text-gray-300 py-8 w-full">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold text-white mb-4">
            PULLHAVEN <sup className="text-xs align-top">ALPHA</sup>
          </h2>
          <p className="mb-4">
            PULLHAVEN is the ultimate All-in-One TCG Database, Deck Builder, and 
            Collection Manager App. Our goal is to provide players and collectors with 
            the best digital tools and resources for Pokémon, Yu-Gi-Oh!, Magic: The 
            Gathering, Digimon, and One Piece card games.
          </p>
          <p className="mb-4">
            Anyone can create decks on PULLHAVEN. Simply <a href="/signup" className="text-white font-semibold hover:underline">register for free</a> and 
            start exploring!
          </p>
          <div className="flex space-x-4">
            <a href="https://x.com/pullhaven" className="text-gray-400 hover:text-white"><FaXTwitter size={24} /></a>
            <a href="https://www.instagram.com/pull.haven/" className="text-gray-400 hover:text-white"><FaInstagram size={24} /></a>
            <a href="https://discord.gg/TTWqq3zc" className="text-gray-400 hover:text-white"><FaDiscord size={24} /></a>
            <a href="https://www.tiktok.com/@pullhaven" className="text-gray-400 hover:text-white"><FaTiktok size={24} /></a>
          </div>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-white mb-4">Products</h3>
          <ul className="space-y-2">
            <li><a href="#" className="hover:text-white">Collection Manager</a></li>
            <li><a href="#" className="hover:text-white">PULLHAVEN Pro</a></li>
          </ul>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-white mb-4">Company</h3>
          <ul className="space-y-2">
            <li><a href="/about" className="hover:text-white">About Us</a></li>
            <li><a href="#" className="hover:text-white">Blog</a></li>
            <li><a href="#" className="hover:text-white">Roadmap</a></li>
            <li><a href="/privacypolicy" className="hover:text-white">Privacy Policy</a></li>
            <li><a href="/tos" className="hover:text-white">Terms of Service</a></li>
            <li><a href="/imprint" className="hover:text-white">Imprint</a></li>
          </ul>
        </div>
      </div>
      
      <div className="mt-8 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center">
        <p>&copy; 2024 PULLHAVEN. All rights reserved.</p>
        <button className="mt-4 md:mt-0 bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-600">
          Feedback geben
        </button>
      </div>
      
      <div className="mt-4 text-xs text-gray-500 space-y-2">
        <p>
          All card images, card names, and other related content are trademarks and copyrights of their respective owners - 
          Pokémon Company International, Konami, Wizards of the Coast, Bandai, and Toei Animation. PULLHAVEN is not affiliated with, 
          endorsed, sponsored, or specifically approved by these companies. This website is not produced by, endorsed by, supported by, 
          or affiliated with any of these companies.
        </p>
        <p>
          Affiliate Link Disclosure: PULLHAVEN is a participant in various affiliate advertising programs. This means that PULLHAVEN may receive a commission when you click on and subsequently make a purchase from affiliate links on this website, at no additional cost to you. These affiliate relationships help support the ongoing operation and maintenance of our free content and services. We are committed to editorial integrity and strive to recommend products, services, and companies that we believe will provide value to our readers and community. Our participation in these programs does not influence our content, opinions, or recommendations. We always encourage our users to perform their own due diligence before making any purchases. For more information about our affiliate partnerships and how they impact our operations, please refer to our Privacy Policy and Terms of Service.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;