import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import TypeIcon from './typeIcon.tsx';
import RarityLegend from './rarityLegend.tsx';
import { ThemeContext } from '../../themeContext.js';

const CardDetails = ({ cardData }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const labelClass = isDarkMode ? 'text-gray-400' : 'text-gray-600';
  const valueClass = isDarkMode ? 'text-white' : 'text-gray-800';
  const borderClass = isDarkMode ? 'border-gray-700' : 'border-gray-200';
  const linkClass = `hover:text-blue-600 ${valueClass}`;

  return (
    <div className={`border rounded-xl overflow-hidden ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}`}>
      <table className="w-full">
        <tbody>
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Name:</td>
            <td className={`py-2 px-4 ${valueClass}`}>{cardData.name.en}</td>
          </tr>
          {cardData.supertype === 'Pokémon' && cardData.evolvesFrom && (
            <tr className={`border-b ${borderClass}`}>
              <td className={`py-2 px-4 ${labelClass}`}>Evolves From:</td>
              <td className={`py-2 px-4 ${valueClass}`}>{cardData.evolvesFrom}</td>
            </tr>
          )}
          {cardData.nationalPokedexNumbers && (
            <tr className={`border-b ${borderClass}`}>
              <td className={`py-2 px-4 ${labelClass}`}>Pokédex Number:</td>
              <td className={`py-2 px-4 ${valueClass}`}>{cardData.nationalPokedexNumbers.join(', ')}</td>
            </tr>
          )}
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Set:</td>
            <td className="py-2 px-4 flex items-center">
              <Link to={`/pokemon-sets/${cardData.Set.id}`} className={`flex items-center hover:text-blue-600 ${valueClass}`}>
                <span className="mr-4">{cardData.Set.name}</span>
                <img 
                  src={`/img/pkm/pokemon_sets/icons/${cardData.Set.id}_symbol.png`}
                  alt={`${cardData.Set.name} icon`} 
                  className="w-11 h-6" 
                />
              </Link>
            </td>
          </tr>
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Set Logo:</td>
            <td className="py-2 px-4">
              <Link to={`/pokemon-sets/${cardData.Set.id}`}>
                <img 
                  src={`/img/pkm/pokemon_sets/images/${cardData.Set.id}_logo.png`}
                  alt={`${cardData.Set.name} logo`} 
                  className="max-w-[150px] h-auto hover:opacity-80 transition-opacity" 
                />
              </Link>
            </td>
          </tr>
          {cardData.supertype === 'Pokémon' && cardData.types && (
            <tr className={`border-b ${borderClass}`}>
              <td className={`py-2 px-4 ${labelClass}`}>Type:</td>
              <td className={`py-2 px-4 ${valueClass}`}>
                {cardData.types.map(type => (
                  <span key={type} className="inline-flex items-center mr-2">
                    <TypeIcon type={type} />
                    <span className="ml-1">{type}</span>
                  </span>
                ))}
              </td>
            </tr>
          )}
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Card Number:</td>
            <td className={`py-2 px-4 ${valueClass}`}>{cardData.number}/{cardData.Set.printedTotal}</td>
          </tr>
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Artist:</td>
            <td className={`py-2 px-4 ${valueClass}`}>
              {cardData.artists.map((artist, index) => (
                <React.Fragment key={artist}>
                  {index > 0 && ', '}
                  <Link 
                    to={`/pokemon-artists/${encodeURIComponent(artist.trim())}`} 
                    className={linkClass}
                  >
                    {artist.trim()}
                  </Link>
                </React.Fragment>
              ))}
            </td>
          </tr>
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Rarity:</td>
            <td className="py-2 px-4">
              <div className="inline-block">
                <RarityLegend rarity={cardData.rarity} isDarkMode={isDarkMode} />
              </div>
            </td>
          </tr>
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Release Date:</td>
            <td className={`py-2 px-4 ${valueClass}`}>{cardData.Set.releaseDate}</td>
          </tr>
          <tr className={`border-b ${borderClass}`}>
            <td className={`py-2 px-4 ${labelClass}`}>Legal:</td>
            <td className={`py-2 px-4 ${valueClass}`}>
              {Object.entries(cardData.legalities)
                .filter(([_, status]) => status === 'Legal')
                .map(([format]) => format)
                .join(', ')}
            </td>
          </tr>
          {cardData.flavorText && (
            <tr className={`border-b ${borderClass}`}>
              <td className={`py-2 px-4 ${labelClass}`}>Flavor Text:</td>
              <td className={`py-2 px-4 ${valueClass} italic`}>"{cardData.flavorText}"</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CardDetails;