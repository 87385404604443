import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FaHeart, FaPlus, FaLayerGroup } from 'react-icons/fa';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import RelatedCards from './components/card/relatedCards.tsx';
import DuplicatesTable from './components/card/duplicatesTable.tsx';
import RarityLegend from './components/card/rarityLegend.tsx';
import MarketPrices from './components/card/marketPrices.tsx';
import PriceAnalysis from './components/card/priceAnalysis.tsx';
import TypeIcon from './components/card/typeIcon.tsx';
import CardDetails from './components/card/cardDetails.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';
import Playability from './components/card/playability.tsx'; 
import LanguageSwitch from './components/card/languageSwitch.tsx';
import { ThemeContext } from './themeContext';

const PokemonCard = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const { cardId } = useParams();
  const [cardData, setCardData] = useState(null);
  const [bgColor, setBgColor] = useState('rgb(0, 0, 0)');
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('EN');
  const [priceTimeframe, setPriceTimeframe] = useState('1M');
  const [priceData, setPriceData] = useState(null);
  const [samePokemonCards, setSamePokemonCards] = useState([]);
  const [sameSetCards, setSameSetCards] = useState([]);
  const [playabilityCards, setPlayabilityCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        setLoading(true);
        // Fetch card data from the new backend route
        const response = await fetch(`/api/cards/${cardId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch card data');
        }
        const fetchedCardData = await response.json();
        
        setCardData(fetchedCardData);
        console.log('Fetched Card Data:', fetchedCardData);  // Debug log

        setLoading(false);
      } catch (err) {
        console.error('Error fetching card data:', err);
        setError('Failed to fetch card data');
        setLoading(false);
      }
    };

    fetchCardData();
  }, [cardId]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const generatePriceData = (days) => {
      const data = [];
      let price = 35;
      for (let i = 0; i < days; i++) {
        price += (Math.random() - 0.5) * 2;
        data.push({
          date: new Date(Date.now() - (days - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
          price: price
        });
      }
      return data;
    };

    const days = priceTimeframe === '1M' ? 30 : priceTimeframe === '6M' ? 180 : 365;
    const data = generatePriceData(days);
    setPriceData({
      labels: data.map(d => d.date),
      datasets: [
        {
          label: 'Price (USD)',
          data: data.map(d => d.price),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    });
  }, [priceTimeframe]);

  useEffect(() => {
    if (cardData && cardData.images.large) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = cardData.images.large;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        let r = 0, g = 0, b = 0;
        for (let i = 0; i < data.length; i += 4) {
          r += data[i];
          g += data[i+1];
          b += data[i+2];
        }
        r = Math.floor(r / (data.length / 4));
        g = Math.floor(g / (data.length / 4));
        b = Math.floor(b / (data.length / 4));
        setBgColor(`rgb(${r}, ${g}, ${b})`);
      };
    }
  }, [cardData]);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    // Here you would typically fetch the card text in the selected language
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!cardData) return <div>No card data found</div>;

  // Debug logs
  console.log('Card Data:', cardData);
  console.log('Rules:', cardData.rules?.en);
  console.log('Text:', cardData.text?.en);

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />

        <main className="flex-1 overflow-x-hidden overflow-y-auto" style={{backgroundColor: 'var(--background-color)'}}>
          <Breadcrumbs cardData={cardData} />
          {/* Top Section */}
          <section className={`py-12 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-gray-100 to-gray-200'}`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Card Image and Actions */}
                <div className="md:col-span-1">
                  <div className={`p-4 rounded-2xl shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <img src={cardData.images.large} alt={cardData.name} className="w-full rounded-xl" />
                  </div>
                  <div className="mt-6 flex justify-between">
                    <button className={`flex items-center justify-center ${isDarkMode ? 'text-gray-300 hover:text-red-400' : 'text-gray-700 hover:text-red-600'} px-4 py-2 rounded-md transition-colors duration-200`}>
                      <FaHeart className="mr-2" /> Wishlist
                    </button>
                    <button className={`flex items-center justify-center ${isDarkMode ? 'text-gray-300 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'} px-4 py-2 rounded-md transition-colors duration-200`}>
                      <FaPlus className="mr-2" /> Collection
                    </button>
                    <button className={`flex items-center justify-center ${isDarkMode ? 'text-gray-300 hover:text-green-400' : 'text-gray-700 hover:text-green-600'} px-4 py-2 rounded-md transition-colors duration-200`}>
                      <FaLayerGroup className="mr-2" /> Deck
                    </button>
                  </div>
                </div>

                {/* Card Details */}
                <div className="md:col-span-2">
                  <div className={`p-6 rounded-2xl shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <h1 className={`text-4xl md:text-5xl font-extrabold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                      {cardData.name.en} <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>#{cardData.number}</span>
                    </h1>
                    <div className="flex flex-wrap items-center space-x-2 mb-4">
                      <span className={`px-3 py-1 rounded-full text-sm font-semibold ${isDarkMode ? 'bg-yellow-700 text-yellow-100' : 'bg-yellow-100 text-yellow-800'}`}>{cardData.supertype}</span>
                      {cardData.subtypes && cardData.subtypes.map((subtype, index) => (
                        <span key={index} className={`px-3 py-1 rounded-full text-sm font-semibold ${isDarkMode ? 'bg-green-700 text-green-100' : 'bg-green-100 text-green-800'}`}>{subtype}</span>
                      ))}
                      <RarityLegend rarity={cardData.rarity} isDarkMode={isDarkMode} />
                    </div>
                    {cardData.supertype === 'Pokémon' && (
                      <div className={`flex items-center text-lg mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                        <p className="mr-4">HP: <span className={`font-bold ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>{cardData.hp}</span></p>
                        <p className="flex items-center">
                          Type: <span className="ml-1">{cardData.types && cardData.types.map(type => <TypeIcon key={type} type={type} className="ml-1" />)}</span>
                        </p>                 
                      </div>
                    )}
                    <LanguageSwitch onLanguageChange={handleLanguageChange} isDarkMode={isDarkMode} />

                    {/* Rules (for all cards) */}
                    {cardData.rules && cardData.rules.en && (
                      <div className="mt-6">
                        <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Rules</h3>
                        {Array.isArray(cardData.rules.en) ? (
                          cardData.rules.en.map((rule, index) => (
                            <p key={index} className={isDarkMode ? 'text-gray-300 mb-2' : 'text-gray-600 mb-2'}>{rule}</p>
                          ))
                        ) : (
                          <p className={isDarkMode ? 'text-gray-300 mb-2' : 'text-gray-600 mb-2'}>{cardData.rules.en}</p>
                        )}
                      </div>
                    )}

                    {/* Text (for Trainer cards) */}
                    {cardData.supertype === 'Trainer' && cardData.text && cardData.text.en && (
                      <div className="mt-6">
                        <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Card Text</h3>
                        {Array.isArray(cardData.text.en) ? (
                          cardData.text.en.map((text, index) => (
                            <p key={index} className={isDarkMode ? 'text-gray-300 mb-2' : 'text-gray-600 mb-2'}>{text}</p>
                          ))
                        ) : (
                          <p className={isDarkMode ? 'text-gray-300 mb-2' : 'text-gray-600 mb-2'}>{cardData.text.en}</p>
                        )}
                      </div>
                    )}

                    {/* Abilities (for Pokémon cards) */}
                    {cardData.supertype === 'Pokémon' && cardData.abilities && (
                      <div className="mt-6">
                        {cardData.abilities.map((ability, index) => (
                          <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                            <div className="flex justify-between items-center mb-2">
                              <h4 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{ability.name}</h4>
                              <span className={`px-3 py-1 rounded-full text-sm font-semibold ${isDarkMode ? 'bg-green-700 text-green-100' : 'bg-green-100 text-green-800'}`}>{ability.type}</span>
                            </div>
                            <p className={isDarkMode ? 'text-gray-200' : 'text-gray-700'}>{ability.text}</p>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Attacks (for Pokémon cards) */}
                    {cardData.supertype === 'Pokémon' && cardData.attacks && (
                      <div className="space-y-4 mt-6">
                        {cardData.attacks.map((attack, index) => (
                          <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                            <div className="flex justify-between items-center mb-2">
                              <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{attack.name}</h3>
                              <div className="flex space-x-2">
                                {attack.cost.map((cost, costIndex) => (
                                  <TypeIcon key={costIndex} type={cost} />
                                ))}
                              </div>
                            </div>
                            <p className={isDarkMode ? 'text-gray-200' : 'text-gray-700'}>{attack.text}</p>
                            {attack.damage && <p className={`mt-2 font-bold ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>Damage: {attack.damage}</p>}
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Additional Info (for Pokémon cards) */}
                    {cardData.supertype === 'Pokémon' && (
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6 text-sm">
                        <div className={`p-4 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-50 border-gray-200'}`}>
                          <h3 className={`font-semibold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>Weakness</h3>
                          {cardData.weaknesses && cardData.weaknesses.length > 0 ? (
                            cardData.weaknesses.map((weakness, index) => (
                              <p key={index} className={`flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                <TypeIcon type={weakness.type} /> <span className="ml-2">{weakness.value}</span>
                              </p>
                            ))
                          ) : (
                            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>None</p>
                          )}
                        </div>
                        <div className={`p-4 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-50 border-gray-200'}`}>
                          <h3 className={`font-semibold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>Resistance</h3>
                          {cardData.resistances && cardData.resistances.length > 0 ? (
                            cardData.resistances.map((resistance, index) => (
                              <p key={index} className={`flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                <TypeIcon type={resistance.type} /> <span className="ml-2">{resistance.value}</span>
                              </p>
                            ))
                          ) : (
                            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>None</p>
                          )}
                        </div>
                        <div className={`p-4 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-50 border-gray-200'}`}>
                          <h3 className={`font-semibold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>Retreat Cost</h3>
                          {cardData.retreatCost && cardData.retreatCost.length > 0 ? (
                            <div className={`flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                              {Array(cardData.convertedRetreatCost).fill().map((_, index) => (
                                <TypeIcon key={index} type={cardData.retreatCost[0]} className="mr-1" />
                              ))}
                            </div>
                          ) : (
                            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>None</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Bottom Section */}
          <section className={`py-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <div className="max-w-7xl mx-auto px-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                <CardDetails cardData={cardData} isDarkMode={isDarkMode} />
                <Playability cardData={cardData} />
                </div>
                
                <div>
                <MarketPrices tcgplayer={cardData.tcgplayer} cardmarket={cardData.cardmarket} isDarkMode={isDarkMode} />
                <PriceAnalysis 
                    priceData={priceData} 
                    priceTimeframe={priceTimeframe} 
                    setPriceTimeframe={setPriceTimeframe}
                  />
                </div>
              </div>
              
              <div className="mt-8">
                <DuplicatesTable cardData={cardData} allCards={sameSetCards} />
              </div>
              <RelatedCards cardData={cardData} />
              <div className="mt-8 text-center text-gray-600 dark:text-gray-400">
                <p>
                  {cardData.tcgplayer?.updatedAt
                    ? `Last Updated: ${new Date(cardData.tcgplayer.updatedAt).toLocaleString()}`
                    : "Last updated date not available"}
                </p>              
              </div>
            </div>
          </section>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default PokemonCard;