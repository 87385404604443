import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CardList = ({ cards, set }) => {
  useEffect(() => {
    console.log('Cards received in CardList:', cards);
  }, [cards]);

  const getCardName = (name) => {
    if (typeof name === 'object' && name.en) {
      return name.en;
    }
    return name;
  };

  const getCardPrice = (card) => {
    console.log('Card data for price:', card);
    if (card.CardPrices && card.CardPrices.length > 0) {
      const cardPrice = card.CardPrices[0];
      console.log('Card price data:', cardPrice);
      if (cardPrice.cardmarket && cardPrice.cardmarket.prices && cardPrice.cardmarket.prices.avg1) {
        const price = cardPrice.cardmarket.prices.avg1;
        return typeof price === 'number' ? `€${price.toFixed(2)}` : `€${price}`;
      }
    } else if (card.cardmarket && card.cardmarket.prices && card.cardmarket.prices.averageSellPrice) {
      const price = card.cardmarket.prices.averageSellPrice;
      return typeof price === 'number' ? `€${price.toFixed(2)}` : `€${price}`;
    }
    return 'N/A';
  };

  const sortedCards = [...cards].sort((a, b) => {
    const aNum = parseInt(a.number);
    const bNum = parseInt(b.number);
    return aNum - bNum;
  });

  return (
    <div className="mt-8">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {sortedCards.map((card) => {
          const cardSet = card.set || set;
          return (
            <Link key={card.id} to={`/pokemon-sets/${cardSet?.id || 'unknown'}/${card.id}`} className="group">
              <div className="relative overflow-hidden rounded-xl shadow-lg">
                <img src={`/img/pokemon_cards_en/images/${card.id}.webp`} alt={getCardName(card.name)} className="w-full" />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-all duration-300 flex flex-col justify-end p-4 opacity-0 group-hover:opacity-100">
                  <div className="text-white">
                    <p className="font-bold text-sm mb-1">{getCardName(card.name)}</p>
                    <p className="text-xs mb-1">{`${card.supertype}${card.subtypes ? ' - ' + card.subtypes.join(', ') : ''}`}</p>
                    <p className="text-xs mb-1">{`Rarity: ${card.rarity}`}</p>
                    <p className="text-xs">Value: {getCardPrice(card)}</p>
                  </div>
                </div>
              </div>
              <p className="mt-2 text-center text-sm text-grey-600">
                {`${getCardName(card.name)} ${card.number}${cardSet && cardSet.printedTotal ? `/${cardSet.printedTotal}` : ''}`}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CardList;