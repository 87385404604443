import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from './themeContext';
import Header from './header.tsx';
import Sidebar from './sidebar.tsx';
import Footer from './footer.tsx';
import { Search, Grid, List, RotateCcw, ArrowLeft, Minus, Heart, X, ChevronDown, MessageSquare, AlignLeft, LayoutGrid, Beaker, Hand, Plus, Wallet, Download } from 'lucide-react';

const DeckBuilder = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [deck, setDeck] = useState({ pokemon: [], trainer: [], energy: [] });
  const [deckName, setDeckName] = useState('Unbenanntes Deck');
  const [viewMode, setViewMode] = useState('grid');
  const [visibility, setVisibility] = useState('public');
  const [inPublicLibrary, setInPublicLibrary] = useState(true);
  const [sidebarView, setSidebarView] = useState('search');
  const [cardFilter, setCardFilter] = useState('all');
  const [format, setFormat] = useState('TCG');
  const [date, setDate] = useState('April 2024');
  const [isFormatDropdownOpen, setIsFormatDropdownOpen] = useState(false);

  const formats = ['TCG', 'OCG', 'Goat', 'Edison', 'Speed Duel'];

  const handleFormatChange = (newFormat) => {
    setFormat(newFormat);
    setIsFormatDropdownOpen(false);
  };


  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    const response = await fetch('/api/cards');
    const data = await response.json();
    setCards(data);
  };

  const handleSearch = async () => {
    const response = await fetch(`/api/cards?search=${searchTerm}`);
    const data = await response.json();
    setCards(data);
  };

  const handleAddCard = (card) => {
    const category = card.supertype.toLowerCase();
    setDeck(prevDeck => ({
      ...prevDeck,
      [category]: [...prevDeck[category], card]
    }));
  };

  const handleRemoveCard = (card) => {
    const category = card.supertype.toLowerCase();
    setDeck(prevDeck => ({
      ...prevDeck,
      [category]: prevDeck[category].filter(c => c.id !== card.id)
    }));
  };

  const getTotalCards = () => {
    return deck.pokemon.length + deck.trainer.length + deck.energy.length;
  };

  const getDeckPrice = () => {
    const total = [...deck.pokemon, ...deck.trainer, ...deck.energy].reduce((sum, card) => sum + (card.cardmarket?.prices?.averageSellPrice || 0), 0);
    return total.toFixed(2);
  };

  const ToggleButton = ({ icon, text, isActive, onClick }) => (
    <button
      onClick={onClick}
      className={`flex items-center justify-center p-2 text-sm ${
        isActive
          ? 'bg-blue-500 text-white'
          : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
      } rounded-md transition-colors duration-200`}
    >
      {icon}
      <span className="ml-2">{text}</span>
    </button>
  );

  return (
    <div className={`flex h-screen ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="min-h-full flex flex-col">
            {/* Top section */}
            <div className="bg-gray-900 text-white">
      <div className="flex items-center justify-between px-4 py-2 border-b border-gray-800">
        <div className="flex items-center space-x-2">
          <button className="text-gray-400 hover:text-white">
            <ArrowLeft size={20} />
          </button>
          <input
            type="text"
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
            className="bg-transparent text-2xl font-bold outline-none"
          />
          <div className="flex items-center space-x-1 text-sm">
            <span className="text-blue-400">TCG</span>
            <span className="text-gray-400">April 2024</span>
            <button className="text-gray-600 hover:text-gray-400">
              <X size={14} />
            </button>
          </div>
        </div>
        <div className="flex items-center space-x-4 text-sm text-gray-400">
          <span>0 MAIN</span>
          <span>0 EXTRA</span>
          <span>0 SIDE</span>
          <span>STATUS</span>
          <span>0/0 HAVES</span>
          <span>0,00 € PREIS</span>
          <button className="bg-blue-600 text-white px-3 py-1 rounded text-xs">
            Gespeichert
          </button>
          <span>vor 4 Stunden</span>
          <button className="text-gray-400 hover:text-white">
            <MessageSquare size={20} />
          </button>
          <button className="text-gray-400 hover:text-white">
            <ChevronDown size={20} />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-1 -mt-4 mb-2">
        <button className="p-2 bg-blue-600 rounded-full shadow-lg">
          <AlignLeft size={20} />
        </button>
        <button className="p-2 bg-gray-800 rounded-full shadow-lg -ml-2">
          <LayoutGrid size={20} />
        </button>
        <button className="p-2 bg-gray-800 rounded-full shadow-lg -ml-2">
          <Beaker size={20} />
        </button>
        <button className="p-2 bg-gray-800 rounded-full shadow-lg -ml-2">
          <Hand size={20} />
        </button>
        <div className="flex items-center bg-gray-800 rounded-full shadow-lg -ml-2 px-2">
          <button><Plus size={16} /></button>
          <button><Minus size={16} /></button>
        </div>
      </div>
      <div className="flex items-center justify-between px-4 py-1 bg-gray-800">
        <div></div>
        <div className="flex items-center space-x-2">
          <button className="text-gray-400 hover:text-white">
            <Wallet size={20} />
          </button>
          <button className="text-gray-400 hover:text-white">
            <Download size={20} />
          </button>
          <button className="px-2 py-1 bg-gray-700 rounded text-xs">
            Auto
          </button>
        </div>
      </div>
    </div>

            {/* Main content area */}
            <div className="flex-1 flex overflow-hidden">
              {/* Left sidebar - Card search and favorites */}
              <div className="w-80 bg-white dark:bg-gray-800 border-r dark:border-gray-700 overflow-y-auto">
                <div className="p-4">
                  <div className="grid grid-cols-2 gap-2 mb-4">
                    <ToggleButton
                      icon={<Search size={16} />}
                      text="Search"
                      isActive={sidebarView === 'search'}
                      onClick={() => setSidebarView('search')}
                    />
                    <ToggleButton
                      icon={<Heart size={16} />}
                      text="Favorites"
                      isActive={sidebarView === 'favorites'}
                      onClick={() => setSidebarView('favorites')}
                    />
                    <ToggleButton
                      icon={null}
                      text="All Cards"
                      isActive={cardFilter === 'all'}
                      onClick={() => setCardFilter('all')}
                    />
                    <ToggleButton
                      icon={null}
                      text="Collection"
                      isActive={cardFilter === 'collection'}
                      onClick={() => setCardFilter('collection')}
                    />
                  </div>

                  {sidebarView === 'search' && (
                    <>
                      <div className="flex items-center mb-4">
                        <input
                          type="text"
                          placeholder="Karten suchen..."
                          className="flex-grow p-2 border-2 border-r-0 rounded-l-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button
                          onClick={handleSearch}
                          className="bg-blue-500 text-white p-2 rounded-r-lg hover:bg-blue-600 transition-colors duration-200 border-2 border-blue-500"
                        >
                          <Search size={20} />
                        </button>
                      </div>
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex space-x-2">
                          <button onClick={() => setViewMode('grid')} className={`p-1 ${viewMode === 'grid' ? 'bg-gray-200 dark:bg-gray-700' : ''}`}>
                            <Grid size={20} />
                          </button>
                          <button onClick={() => setViewMode('list')} className={`p-1 ${viewMode === 'list' ? 'bg-gray-200 dark:bg-gray-700' : ''}`}>
                            <List size={20} />
                          </button>
                        </div>
                        <span className="text-sm text-gray-500 dark:text-gray-400">{cards.length} Karten (19 ms)</span>
                        <button className="text-blue-500" onClick={fetchCards}>
                          <RotateCcw size={20} />
                        </button>
                      </div>
                      {/* Card search results */}
                      <div className={`mt-4 ${viewMode === 'grid' ? 'grid grid-cols-3 gap-2' : 'space-y-2'}`}>
                        {cards.map(card => (
                          <div
                            key={card.id}
                            className="relative cursor-pointer"
                            onClick={() => handleAddCard(card)}
                          >
                            <img src={card.images.small} alt={card.name} className="w-full h-auto rounded" />
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {sidebarView === 'favorites' && (
                    <div className="text-center text-gray-500 dark:text-gray-400 mt-20">
                      Your favorite cards will appear here.
                    </div>
                  )}
                </div>
              </div>

              {/* Main content - Deck building area */}
              <div className="flex-1 overflow-y-auto p-6">
                {getTotalCards() === 0 ? (
                  <div className="text-center text-gray-500 dark:text-gray-400 mt-20">
                    Dieses Deck ist leer.<br />
                    Füge Karten über die Kartensuche hinzu oder importiere ein Deck.
                  </div>
                ) : (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                      <h2 className="text-lg font-semibold mb-2 dark:text-white">Pokémon ({deck.pokemon.length})</h2>
                      {deck.pokemon.map(card => (
                        <div key={card.id} className="flex items-center justify-between mb-2">
                          <span className="dark:text-white">{card.name}</span>
                          <button onClick={() => handleRemoveCard(card)} className="text-red-500">
                            <Minus size={16} />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                      <h2 className="text-lg font-semibold mb-2 dark:text-white">Trainer ({deck.trainer.length})</h2>
                      {deck.trainer.map(card => (
                        <div key={card.id} className="flex items-center justify-between mb-2">
                          <span className="dark:text-white">{card.name}</span>
                          <button onClick={() => handleRemoveCard(card)} className="text-red-500">
                            <Minus size={16} />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                      <h2 className="text-lg font-semibold mb-2 dark:text-white">Energy ({deck.energy.length})</h2>
                      {deck.energy.map(card => (
                        <div key={card.id} className="flex items-center justify-between mb-2">
                          <span className="dark:text-white">{card.name}</span>
                          <button onClick={() => handleRemoveCard(card)} className="text-red-500">
                            <Minus size={16} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Right sidebar - Deck information and publishing */}
              <div className="w-80 bg-white dark:bg-gray-800 border-l dark:border-gray-700 overflow-y-auto">
                <div className="p-4">
                  <h2 className="text-lg font-semibold mb-4 dark:text-white">Themen</h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                    Füge mehr Karten hinzu, um passende Themen auswählen zu können.
                  </p>
                  <h2 className="text-lg font-semibold mb-4 dark:text-white">Veröffentlichen</h2>
                  <div className="mb-4">
                    <span className="text-sm font-semibold dark:text-white">Sichtbarkeit</span>
                    <div className="flex mt-2 space-x-2">
                      <button 
                        onClick={() => setVisibility('public')}
                        className={`px-3 py-1 rounded text-sm ${visibility === 'public' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
                      >
                        Öffentlich
                      </button>
                      <button 
                        onClick={() => setVisibility('unlisted')}
                        className={`px-3 py-1 rounded text-sm ${visibility === 'unlisted' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
                      >
                        Nicht gelistet
                      </button>
                      <button 
                        onClick={() => setVisibility('private')}
                        className={`px-3 py-1 rounded text-sm ${visibility === 'private' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}`}
                      >
                        Privat
                      </button>
                    </div>
                  </div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                    {visibility === 'public' && 'Dein Deck ist für jeden sichtbar.'}
                    {visibility === 'unlisted' && 'Dein Deck ist nur über einen direkten Link zugänglich.'}
                    {visibility === 'private' && 'Dein Deck ist nur für dich sichtbar.'}
                  </p>
                  <div className="flex items-center mb-4">
                  <input 
                      type="checkbox" 
                      id="deckLibrary" 
                      checked={inPublicLibrary}
                      onChange={() => setInPublicLibrary(!inPublicLibrary)}
                      className="mr-2" 
                    />
                    <label htmlFor="deckLibrary" className="text-sm text-gray-700 dark:text-gray-300">
                      Öffentliche Deck-Bibliothek
                    </label>
                  </div>
                  <a href="#" className="text-sm text-blue-500 hover:underline">
                    Warum ist mein Deck nicht sichtbar?
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default DeckBuilder;