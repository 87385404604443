import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import { ThemeContext } from './themeContext';
import { ChevronLeft, ChevronRight, Loader } from 'lucide-react';

const ArtistCard = ({ artist, cards }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const nextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const prevCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded shadow-md p-4 flex flex-col items-center">
      <div className="relative w-full h-32 mb-4">
        <button onClick={prevCard} className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
          <ChevronLeft className="text-gray-500 dark:text-gray-400" />
        </button>
        <div className="overflow-hidden h-full flex items-center justify-center">
          <img 
            src={cards[currentCardIndex].images.small} 
            alt={cards[currentCardIndex].name} 
            className="h-full w-auto object-contain transform scale-75 transition-transform duration-300"
          />
        </div>
        <button onClick={nextCard} className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <ChevronRight className="text-gray-500 dark:text-gray-400" />
        </button>
      </div>
      <Link 
        to={`/artist/${encodeURIComponent(artist)}`} 
        className="text-lg font-semibold text-blue-600 dark:text-blue-400 hover:underline"
      >
        {artist}
      </Link>
      <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
        {cards.length} card{cards.length !== 1 ? 's' : ''}
      </p>
    </div>
  );
};

const ArtistsPage = () => {
  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const response = await axios.get('/api/artists');
        setArtists(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching artists:', error);
        setError('Failed to load artists');
        setIsLoading(false);
      }
    };

    fetchArtists();
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const getArtistKey = (artist) => {
    if (artist.startsWith('"')) {
      return artist[1].toUpperCase();
    }
    if (/^\d/.test(artist)) {
      return '#';
    }
    return artist[0].toUpperCase();
  };

  const groupedArtists = artists.reduce((acc, artist) => {
    const key = getArtistKey(artist.artist);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(artist);
    return acc;
  }, {});

  Object.keys(groupedArtists).forEach(key => {
    groupedArtists[key].sort((a, b) => a.artist.localeCompare(b.artist));
  });

  const sortedKeys = Object.keys(groupedArtists).sort((a, b) => {
    if (a === '#') return -1;
    if (b === '#') return 1;
    return a.localeCompare(b);
  });

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 dark:text-white">
              Pokémon TCG Artists
            </h1>

            {!isLoading && !error && (
              <div className="mb-8 flex flex-wrap justify-center">
                {sortedKeys.map(letter => (
                  <a 
                    key={letter}
                    href={`#${letter}`}
                    className="m-1 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm sm:text-base"
                  >
                    {letter}
                  </a>
                ))}
              </div>
            )}

            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <Loader className="animate-spin h-8 w-8 text-blue-500" />
              </div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : (
              sortedKeys.map(letter => (
                <div key={letter} id={letter} className="mb-12">
                  <h2 className="text-6xl font-bold mb-6 text-gray-300 dark:text-gray-700">
                    {letter}
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {groupedArtists[letter].map((artistData) => (
                      <ArtistCard key={artistData.artist} artist={artistData.artist} cards={artistData.cards} />
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default ArtistsPage;