import React, { useContext } from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { ThemeContext } from '../../themeContext.js';

const Playability = ({ cardData }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const playabilityRating = 2.5; // Example rating out of 5

  const getPlayabilityStatus = (rating) => {
    if (rating >= 4) return { text: 'High Playability', color: 'green' };
    if (rating >= 2.5) return { text: 'Moderate Playability', color: 'yellow' };
    return { text: 'Low Playability', color: 'red' };
  };

  const status = getPlayabilityStatus(playabilityRating);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-yellow-400" />);
      } else if (i - 0.5 <= rating) {
        stars.push(<FaStarHalfAlt key={i} className="text-yellow-400" />);
      } else {
        stars.push(<FaStar key={i} className={isDarkMode ? "text-gray-600" : "text-gray-300"} />);
      }
    }
    return stars;
  };

  const getStatusStyles = (color) => {
    const baseClasses = "p-4 mb-4 rounded-r-lg";
    if (isDarkMode) {
      return {
        green: `bg-green-900 border-l-4 border-green-500 text-green-100 ${baseClasses}`,
        yellow: `bg-yellow-900 border-l-4 border-yellow-500 text-yellow-100 ${baseClasses}`,
        red: `bg-red-900 border-l-4 border-red-500 text-red-100 ${baseClasses}`,
      }[color];
    } else {
      return `bg-${color}-100 border-l-4 border-${color}-500 text-${color}-700 ${baseClasses}`;
    }
  };

  return (
    <div className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border rounded-xl overflow-hidden mt-4`}>
      <div className="p-6">
        <div className={getStatusStyles(status.color)}>
          <div className="flex justify-between items-center">
            <p className="font-bold">{status.text}</p>
            <div className="flex">
              {renderStars(playabilityRating)}
            </div>
          </div>
          <p className="mt-2">This card sees some play in niche decks but is not a staple in the current meta.</p>
        </div>
        <p className={`font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Key Points:</p>
        <ul className={`list-disc list-inside ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          <li>High HP makes it a durable Basic Pokémon</li>
          <li>Scrunch ability can provide situational protection</li>
          <li>Double-edge attack has high damage but significant drawback</li>
        </ul>
      </div>
    </div>
  );
};

export default Playability;
