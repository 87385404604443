import React from 'react';
import { useLoading } from './loadingContext.js';

const LoadingScreen = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center z-50">
      <div className="text-center">
        <div className="animate-pulse flex space-x-4 mb-8">
          <div className="rounded-full bg-white h-4 w-4"></div>
          <div className="rounded-full bg-white h-4 w-4"></div>
          <div className="rounded-full bg-white h-4 w-4"></div>
        </div>
        <h2 className="text-3xl font-semibold text-white mb-4">Loading...</h2>
        <p className="text-xl text-white opacity-75">Preparing your TCG experience</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
