import React, { useState, useContext, useEffect } from 'react';
import { Menu, Search, Sun, Moon, ChevronDown, LogOut, User, Settings, Archive, Star, Smartphone, Book, HelpCircle } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeContext } from './themeContext';

// Import TCG logos
import digimonLogo from './img/digi/digimon_card_game.webp';
import magicLogo from './img/magic/magic_card_game.png';
import onePieceLogo from './img/op/one_piece_card_game.webp';
import pokemonLogo from './img/pkm/pokemon_card_game.png';
import yugiohLogo from './img/ygo/one_piece_card_game.webp';

const tcgOptions = [
  { id: 'pokemon', logo: pokemonLogo, alt: 'Pokemon TCG', active: true },
  { id: 'yugioh', logo: yugiohLogo, alt: 'Yu-Gi-Oh! TCG', active: false },
  { id: 'magic', logo: magicLogo, alt: 'Magic: The Gathering', active: false, darkModeAdjust: true },
  { id: 'digimon', logo: digimonLogo, alt: 'Digimon TCG', active: false },
  { id: 'onepiece', logo: onePieceLogo, alt: 'One Piece TCG', active: false, darkModeAdjust: true },
];

interface HeaderProps {
  toggleMobileSidebar: () => void;
  isMobile: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleMobileSidebar, isMobile }) => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const username = localStorage.getItem('username');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/');
  };

  return (
    <header className="bg-white dark:bg-gray-900 shadow-md w-full py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          {isMobile && (
            <button
              onClick={toggleMobileSidebar}
              className="text-gray-500 dark:text-gray-400 mr-4 rounded"
            >
              <Menu className="h-6 w-6" />
            </button>
          )}

          <div className="flex-grow flex items-center justify-center lg:justify-start">
            <div className="relative w-full max-w-2xl">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search"
                className="pl-12 pr-4 py-3 w-full rounded-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-lg"
              />
            </div>
          </div>

          {!isMobile && (
            <div className="flex items-center space-x-6 ml-6">
              <div className="flex space-x-2">
                {tcgOptions.map((tcg) => (
                  <div key={tcg.id} className="relative group">
                    {tcg.active ? (
                      <Link to="/pokemon-sets">
                        <img 
                          src={tcg.logo} 
                          alt={tcg.alt} 
                          className={`h-8 w-8 object-contain ${tcg.darkModeAdjust ? 'dark:invert' : ''}`}
                        />
                      </Link>
                    ) : (
                      <img 
                        src={tcg.logo} 
                        alt={tcg.alt} 
                        className={`h-8 w-8 object-contain filter grayscale opacity-50 cursor-not-allowed ${tcg.darkModeAdjust ? 'dark:invert' : ''}`}
                      />
                    )}
                    {!tcg.active && (
                      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                        Coming Soon
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <button
                onClick={toggleDarkMode}
                className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                {isDarkMode ? (
                  <Sun className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                ) : (
                  <Moon className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                )}
              </button>
              <div className="relative group">
                <select className="appearance-none bg-transparent border-none text-gray-700 dark:text-gray-300 pr-8 cursor-pointer">
                  <option value="de" disabled>🇩🇪 DE</option>
                  <option value="en" >🇬🇧 EN</option>
                  <option value="fr" disabled>🇫🇷 FR</option>
                </select>
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
                  Coming Soon
                </div>
              </div>
              {username ? (
                <div className="relative">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex items-center space-x-2 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                  >
                    <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white">
                      {username.charAt(0).toUpperCase()}
                    </div>
                    <span>{username}</span>
                    <ChevronDown className="h-4 w-4" />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-56 bg-white dark:bg-gray-800 rounded shadow-lg py-1">
                      <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <User className="inline-block mr-2 h-4 w-4" />
                        My Profile
                      </Link>
                      <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <Settings className="inline-block mr-2 h-4 w-4" />
                        Settings
                      </Link>
                      <Link to="/collection" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <Archive className="inline-block mr-2 h-4 w-4" />
                        Collection Manager
                      </Link>
                      <Link to="/support" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <Star className="inline-block mr-2 h-4 w-4" />
                        PULLHAVEN <span className="bg-blue-500 text-white px-1 rounded text-xs">PRO</span>
                      </Link>
                      <Link to="/support" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <HelpCircle className="inline-block mr-2 h-4 w-4" />
                        Support
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <LogOut className="inline-block mr-2 h-4 w-4" />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex space-x-4">
                  <Link
                    to="/login"
                    className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-4 py-2 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/signup"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
                  >
                    Register
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;