import React, { useContext } from 'react';
import { ThemeContext } from '../../themeContext.js'; // Adjust the import path as needed

const SetStatistics = ({ set, cards }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const rarityCount = cards.reduce((acc, card) => {
    acc[card.rarity] = (acc[card.rarity] || 0) + 1;
    return acc;
  }, {});

  const totalCards = cards.length;
  // Dummy value for collected cards (50% of total)
  const collectedCards = Math.floor(totalCards / 2);

  const bgClass = isDarkMode ? 'bg-gray-700' : 'bg-gray-50';
  const borderClass = isDarkMode ? 'border-gray-600' : 'border-gray-200';
  const textClass = isDarkMode ? 'text-white' : 'text-gray-800';
  const subTextClass = isDarkMode ? 'text-gray-300' : 'text-gray-600';
  const headingClass = isDarkMode ? 'text-gray-200' : 'text-gray-700';

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
        <div className={`${bgClass} p-6 rounded-xl border ${borderClass}`}>
          <h3 className={`text-xl font-semibold mb-4 ${headingClass}`}>Rarity Distribution</h3>
          <ul className="space-y-2">
            {Object.entries(rarityCount).map(([rarity, count]) => (
              <li key={rarity} className="flex justify-between">
                <span className={subTextClass}>{rarity}:</span>
                <span className={`font-semibold ${textClass}`}>{count}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={`${bgClass} p-6 rounded-xl border ${borderClass}`}>
          <h3 className={`text-xl font-semibold mb-4 ${headingClass}`}>Set Information</h3>
          <p className={`text-2xl font-bold ${textClass} mb-2`}>{totalCards} Total Cards</p>
          <p className={`text-sm ${subTextClass}`}>
            {set.printedTotal} printed + {totalCards - set.printedTotal} special cards
          </p>
          {set.hasTrainerGallery && (
            <p className={`text-sm ${subTextClass} mt-2`}>Includes Trainer Gallery</p>
          )}
          {set.hasShinySet && (
            <p className={`text-sm ${subTextClass} mt-2`}>Includes Shiny Set</p>
          )}
          {set.hasCelebrationsClassic && (
            <p className={`text-sm ${subTextClass} mt-2`}>Includes Celebrations Classic</p>
          )}
          <h3 className={`text-xl font-semibold mb-4 mt-6 ${headingClass}`}>Set Completion (Dummy)</h3>
          <p className={`text-2xl font-bold ${textClass} mb-2`}>{collectedCards} / {totalCards}</p>
          <div className={`w-full ${isDarkMode ? 'bg-gray-600' : 'bg-gray-200'} rounded-full h-4 mb-4`}>
            <div 
              className="bg-blue-600 h-4 rounded-full" 
              style={{ width: `${(collectedCards / totalCards) * 100}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetStatistics;