import React, { useState, useEffect } from 'react';
import Header from './header.tsx';
import Footer from './footer.tsx';
import Sidebar from './sidebar.tsx';
import Breadcrumbs from './components/card/breadcrumbs.tsx';

const PrivacyPolicyPage = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar 
        isMobile={isMobile} 
        toggleMobileSidebar={toggleMobileSidebar} 
        isOpen={isMobileSidebarOpen} 
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleMobileSidebar={toggleMobileSidebar} isMobile={isMobile} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <Breadcrumbs />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
            
            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
              <p>PULLHAVEN GmbH ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website, mobile application, and any related services (collectively, the "Service").</p>
              <p className="mt-2">We are committed to complying with the General Data Protection Regulation (GDPR) and other applicable data protection laws. By using our Service, you consent to the data practices described in this policy.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">2. Information We Collect</h2>
              <p>We collect several types of information from and about users of our Service, including:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Personal data: Such as name, email address, postal address, phone number, and payment information.</li>
                <li>Usage data: Information on how you use the Service, including your browsing history, search queries, and interaction with features.</li>
                <li>Device data: Information about your device, IP address, browser type, and operating system.</li>
                <li>Location data: With your consent, we may collect and process information about your actual location.</li>
              </ul>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">3. How We Collect Information</h2>
              <p>We collect information through:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Direct interactions: When you create an account, make a purchase, or contact us.</li>
                <li>Automated technologies: As you navigate through our Service, we may automatically collect technical data about your equipment and browsing actions.</li>
                <li>Third parties: We may receive information about you from various third parties, such as analytics providers or advertising networks.</li>
              </ul>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">4. How We Use Your Information</h2>
              <p>We use your information for various purposes, including:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Providing and maintaining our Service</li>
                <li>Processing transactions and sending related information</li>
                <li>Sending promotional communications (with your consent)</li>
                <li>Improving and personalizing our Service</li>
                <li>Analyzing usage of our Service</li>
                <li>Detecting, preventing, and addressing technical issues</li>
              </ul>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">5. Third-Party Services</h2>
              <p>We use various third-party services to support our Service. These include:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Brevo and Klaviyo for email marketing</li>
                <li>Facebook Ads, Google Ads for advertising</li>
                <li>Instagram, TikTok, X (Twitter), and Discord for social media integration and communication</li>
                <li>CardMarket, TCGPlayer, and eBay for price data and marketplace integration</li>
              </ul>
              <p className="mt-2">Each of these services may collect and process your data according to their own privacy policies. We encourage you to review these policies.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">6. Cookies and Tracking Technologies</h2>
              <p>We use cookies and similar tracking technologies to track activity on our Service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">7. Data Transfers</h2>
              <p>Your information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside Germany and choose to provide information to us, please note that we transfer the data to Germany and process it there.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">8. Data Retention</h2>
              <p>We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">9. Your Data Protection Rights Under GDPR</h2>
              <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. PULLHAVEN aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal data. Your rights include:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>The right to access, update or delete your personal data</li>
                <li>The right of rectification</li>
                <li>The right to object to processing</li>
                <li>The right of restriction</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent</li>
              </ul>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">10. Changes to This Privacy Policy</h2>
              <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "effective date" at the top of this Privacy Policy.</p>
            </section>

            <section className="bg-white shadow rounded-lg p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">11. Contact Us</h2>
              <p>If you have any questions about this Privacy Policy, please contact us at:</p>
              <p>PULLHAVEN</p>
              <p>Email: info@pullhaven.com</p>
              <p>Address: Vladimir Kusnezow, Stengelestraße 23, 22111 Hamburg</p>
            </section>

            <p className="mt-8 text-sm text-gray-600">
              Effective date: 24.08.2024
            </p>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;